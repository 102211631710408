import React from "react";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";
import { getTrainingStatus } from "components/helpers/resources/training";

import TrainingStatus from "components/training/TrainingStatus";
import TrainingRowBookingPill from "components/mobileTrainingProfile/components/homeScreen/TrainingRowBookingPill";

export default function TrainingRow({
  course,
  latestTraining,
  booking,
  registration,
  isRequired,
  requiredSource,
  onViewCourseDetailsClick
}) {
  const currentTrainingStatus = getTrainingStatus({ course, training: latestTraining });

  const handleRowClick = () => {
    onViewCourseDetailsClick({ id: course.id, isRequired })
  }

  return (
    <tr className="fh-49 tw-cursor-pointer hover:tw-bg-grey-025" onClick={handleRowClick}>
      <td className="tw-border-grey-100 tw-px-4">
        <div className={`flex flex--vertically-centered fh-32`}>
          <div className="tw-flex-none">
            <TrainingStatus
              currentTrainingStatus={currentTrainingStatus}
              isRequired={isRequired}
              requiredSource={requiredSource}
            />
          </div>
          <div className='tw-flex-auto truncated-text-container truncated-text-container--auto'>
            <span className="tw-text-m tw-text-grey-900 tw-font-medium tw-ml-2.5">{course.attributes.name}</span>
          </div>
          {booking && (
            <div className="tw-flex-none">
              <TrainingRowBookingPill
                course={course}
                booking={booking}
                registration={registration}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

TrainingRow.propTypes = {
  course: resourceShape('course').isRequired,
  latestTraining: resourceShape('training'),
  booking: resourceShape('booking'),
  registration: resourceShape('registration'),
  isRequired: PropTypes.bool,
  requiredSource: PropTypes.string,
  onViewCourseDetailsClick: PropTypes.func.isRequired
}
