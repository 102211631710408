import React from "react";
import moment from "moment";

import { resourceShape } from "components/helpers/serialisableResources";

import { useCurrentActor } from "components/contexts/CurrentActor";

import DesktopMobileIcon from "-!svg-react-loader?name=DesktopMobileIcon!icons/desktop-mobile.svg";
import CalendarIcon from "-!svg-react-loader?name=CalendarIcon!icons/calendar.svg";

import ActionsPill from "components/application/ActionsPill";

export default function TrainingRowBookingPill({
  course,
  booking,
  registration
}) {
  const currentActor = useCurrentActor();

  const courseHasELearningCourse = currentActor.isAllowedFeature('e_learning') && !!course.relationships.eLearningCourse.data;
  const formattedBookingDate = (booking.attributes.date ? moment.parseZone(booking.attributes.date) : null)?.format("D MMM YY");

  return courseHasELearningCourse ? (
    <ActionsPill
      color="cyan"
      displayText={`${registration ? "Continue" : "Start"} eLearning`}
      icon={<DesktopMobileIcon height={16} width={16} />}
    />
  ): (
    <ActionsPill
      color="cyan"
      displayText={`Booked for ${formattedBookingDate}`}
      icon={<CalendarIcon height={16} width={16} />}
    />
  )
}

TrainingRowBookingPill.propTypes = {
  course: resourceShape('course').isRequired,
  booking: resourceShape('booking').isRequired,
  registration: resourceShape('registration')
}
