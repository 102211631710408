import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import { formatCompanyOption } from 'components/helpers/companies';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function SelectDivision(props) {
  const currentActor = useCurrentActor();
  const isNotPrimaryDivision = !currentActor.division.attributes.primary;
  const primaryDivision = props.companies.find(company => company.attributes.primary);

  const {
    label,
    sidePanelContext,
    companies,
    assignedDivision,
    collectionSelectProps = {},
    onChange,
  } = props

  useEffect(() => {
    if (sidePanelContext === 'new') {
      onChange({ value: { companyId: currentActor.division.id, subcontractorId: ""}}, {action: "select-option", option: undefined, name: "company"})
    }
  }, [])

  const assignableOptions = () => {
    return companies.map(company => formatCompanyOption(company));
  };

  const companyValue = assignedDivision ? formatCompanyOption(assignedDivision) : '';

  return (
    <div className={`m-l-30 tooltip-parent`}>
      <CollectionSelect
        label={label}
        name='company'
        value={companyValue}
        isRequired={true}
        options={assignableOptions()}
        onChange={onChange}
        modifiers={['typeable-search']}
        isDisabled={isNotPrimaryDivision}
        { ...collectionSelectProps }
      />
      {isNotPrimaryDivision && <Tooltip className='pos-t-24' trigger='hover' placement='top' tooltip={`This can only be changed from the ${primaryDivision.attributes.name} division`} />}
    </div>
  )
}

SelectDivision.propTypes = {
  sidePanelContext: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  collectionSelectProps: PropTypes.object,
  onChange: PropTypes.func.isRequired
};
