import React, { useState } from "react";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";

const moreBreakpoint = 3;

export default function TeamsTab({
  teams
}) {
  const [showAllTeams, setShowAllTeams] = useState(false);

  const visibleTeams = showAllTeams ? teams : teams.slice(0, moreBreakpoint);

  return (
    <div className="tw-border-solid tw-border-1 tw-rounded-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-py-6">
      {teams.length > 0 ? (
        <>
          <ul className="tw-list-none tw-m-0 tw-p-0">
            {visibleTeams.map((team) => (
              <li className="tw-mb-2 last:tw-mb-0" key={team.id}>
                <span className="tw-inline-block tw-py-1.5 tw-px-4 tw-rounded-[16px] tw-break-words tw-bg-grey-050">
                  {team.attributes.name}
                </span>
              </li>
            ))}
          </ul>
          {!showAllTeams && teams.length > moreBreakpoint && (
            <div className='flex flex--vertically-centered tw-mt-4'>
              <button
                className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                onClick={() => setShowAllTeams(true)}
              >
                Show all ({teams.length - moreBreakpoint} more)
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="tw-text-grey-500">Not a member of any teams</div>
      )}
    </div>
  )
}

TeamsTab.propTypes = {
  teams: PropTypes.arrayOf(resourceShape('team')).isRequired
}
