import React from "react";
import PropTypes from "prop-types";

export default function Modal({
  isOpen,
  closeModal,
  children
}) {
  const handleWrapperClick = (event) => {
    if (event.target.getAttribute("data-element-name") === "mobile-modal-content-wrapper") closeModal()
  }

  return (
    <div className={isOpen ? "tw-visible" : "tw-hidden"}>
      <div className="tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-bg-grey-900 tw-opacity-75 tw-z-[999]"></div>
      <div data-element-name="mobile-modal-content-wrapper" className="tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex tw-items-center tw-justify-center tw-animate-fade-in-quick tw-z-[9999]" onClick={handleWrapperClick}>
        <div className="tw-w-full tw-max-w-3xl tw-p-6">
          <div className="tw-bg-white tw-rounded-2xl">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
