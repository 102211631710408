import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import voca from 'voca';
import pluralize from 'pluralize';
import moment from 'moment';

import { resourcesAsOptions } from 'components/helpers/forms';
import { sendAnalytics } from 'components/helpers/analytics';
import { displayCurrencyString, currencyNumberToCurrencyString } from 'components/helpers/currency';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import CheckboxField from 'components/application/CheckboxField';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import RadioField from 'components/application/RadioField';
import CustomELearningCourseCard from 'components/courses/CustomELearningCourseCard';
import CourseSidePanelProviderForm from 'components/courses/sidepanel/CourseSidePanelProviderForm';
import CourseSidePanelAutoEnrolmentForm from 'components/courses/sidepanel/CourseSidePanelAutoEnrolmentForm';

const renewalIntervalOptions = ['day', 'week', 'month', 'year'];

export default function CourseSidePanelForm(props) {
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const {
    currentCourse,
    eLearningCourse,
    requestError,
    removeErrorStyling,
    onInputChange,
    onOptionChange,
    onELearningProviderSelectedChange,
    assignableRoles,
    bodyRef,
    onCourseCompanyRoleRequiredChange,
    onCourseCompanyRoleDelete,
    onCourseRolesOptionChange
  } = props;

  const currentActor = useCurrentActor();

  const currencyCode = currentActor.division.attributes.currencyCode;

  const convertValue = (value) => value === 'true';

  const expiryComparisonValuesAreSet = currentCourse.renewalFrequency && currentCourse.renewalInterval && currentCourse.expiringDuration;
  const shouldShowExpiryLengthWarning = expiryComparisonValuesAreSet && moment.duration(currentCourse.renewalFrequency, currentCourse.renewalInterval) < moment.duration(currentCourse.expiringDuration, 'days');

  // filter out already selected courses
  const selectedRoleIds = currentCourse.courseCompanyRoles ? currentCourse.courseCompanyRoles.map(ccr => ccr.companyRoleId) : [];
  const selectableRoles = assignableRoles.filter(role => !selectedRoleIds.includes(role.id));

  const isELearningCustom = eLearningCourse && eLearningCourse.attributes.custom;
  const eLearningCourseCost = isELearningCustom && currencyNumberToCurrencyString({ number: eLearningCourse.attributes.cost, currencyCode });

  // order courseCompanyRoles alphabetically
  const orderedCourseCompanyRoles = currentCourse.courseCompanyRoles.map(courseCompanyRole => {
      const roleId = courseCompanyRole.companyRoleId;
      const rolePosition = assignableRoles.find(role => role.id == roleId).attributes.position;
      return { roleId: roleId, rolePosition: rolePosition, required: courseCompanyRole.required }
    }).sort((a, b) => a.rolePosition.localeCompare(b.rolePosition, 'en', { sensitivity: 'base' }));

  const isCourseExpiryDateReminderEditable = trainingRegisterResourceManagementContext.isCourseExpiryDateEditable

  return (
    <React.Fragment>
      <div className='popup__body-form p-b-0 m-b-32'>
        <ErrorMessage
          wrapperClassName='form-container'
          validationErrors={requestError.validationErrors}
          isFallback={requestError.isFallback}
        />
        <div className='form-container'>
          <TextField
            label='Course name'
            name='name'
            value={currentCourse.name}
            isRequired={true}
            removeErrorStyling={removeErrorStyling}
            fieldError={requestError.validationErrors.name}
            onChange={onInputChange}
          />
        </div>
      </div>

      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0'/>

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <label className='field__label tw-font-medium'>Expiration</label>
          <RadioField
            checked={currentCourse.expires}
            onChange={(event) => {onInputChange(event, convertValue)}}
            value={true}
            name='expires'
            label={'Training expires'}
          />
          {currentCourse.expires && (
            <div className='m-l-28'>
              <div className='side-panel__inline-container'>
                <div className='field--tab-inline m-r-6'>
                  <TextField
                    label='Renewal frequency'
                    name='renewalFrequency'
                    isDisabled={false}
                    isRequired={false}
                    removeErrorStyling={removeErrorStyling}
                    value={currentCourse.renewalFrequency}
                    fieldError={requestError.validationErrors.renewalFrequency}
                    onChange={onInputChange}
                  />
                </div>
                <div className='field--tab-inline m-l-6'>
                  <CollectionSelect
                    name='renewalInterval'
                    value={{ label: pluralize(voca.titleCase(currentCourse.renewalInterval)), value: currentCourse.renewalInterval }}
                    isRequired={true}
                    isDisabled={false}
                    isSearchable={false}
                    options={
                      renewalIntervalOptions.map(option => {
                        return { label: pluralize(voca.titleCase(option)), value: option }
                      })
                    }
                    onChange={onOptionChange}
                  />
                </div>
              </div>
              <div className='tw-inline-flex tw-items-center tw-gap-2'>
                <div className='tw-w-[140px]'>
                  <TextField
                    label='Expiry reminder'
                    name='expiringDuration'
                    isDisabled={!isCourseExpiryDateReminderEditable}
                    isRequired={false}
                    tooltip={`The course status will turn amber and an email notification will be sent${isCourseExpiryDateReminderEditable ? '' : '. This can be customised by upgrading your plan.'}`}
                    removeErrorStyling={removeErrorStyling}
                    type={'number'}
                    value={currentCourse.expiringDuration}
                    fieldError={requestError.validationErrors.expiringDuration}
                    onChange={(e) => {
                      sendAnalytics('Course expiry reminder edited', { currentUser: currentActor.user })
                      onInputChange(e)
                    }}
                    min={1}
                    inputClassNames={'fw-72'}
                  />
                </div>
                <div className='tw-text-m'>
                  days before course expiry
                </div>
              </div>
              {eLearningCourse && (
                <div className='tw-flex tw-mb-6'>
                  <CheckboxField
                    className='tw-w-auto'
                    label='Show expiry date on certificate'
                    name='certificateExpiryDateIncluded'
                    checked={currentCourse.certificateExpiryDateIncluded}
                    onChange={onInputChange}
                  />
                  <div className='tw-ml-2'>
                    <Tooltip className='tooltip-dark--max-w-xxs' placement='top' trigger='hover' tooltip='Expiry date is calculated based on renewal frequency at the time of certificate being awarded'>
                      <CircleQuestion />
                    </Tooltip>
                  </div>
                </div>
              )}
              {shouldShowExpiryLengthWarning && (
                <div className='tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 m-0'>
                  The expiry reminder is longer than the renewal frequency. This might stop reminder emails being sent.
                </div>
              )}
            </div>
          )}
          <RadioField
            checked={!currentCourse.expires}
            onChange={(event) => {onInputChange(event, convertValue)}}
            value={false}
            name='expires'
            label={'Training does not expire'}
          />
        </div>
      </div>

      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0'/>

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <label className='field__label tw-font-medium' htmlFor='requiresEvidence'>
            Evidence
            <Tooltip placement='top' trigger='hover' tooltip='E.g. certificates or cards'>
              <CircleQuestion />
            </Tooltip>
          </label>
          <RadioField
            checked={currentCourse.requiresEvidence}
            onChange={(event) => {onInputChange(event, convertValue)}}
            value={true}
            name='requiresEvidence'
            label={'Requires evidence'}
          />
          <RadioField
            checked={!currentCourse.requiresEvidence}
            onChange={(event) => {onInputChange(event, convertValue)}}
            value={false}
            name='requiresEvidence'
            label={'Does not require evidence'}
          />
        </div>
      </div>

      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0'/>

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <CollectionSelect
            label='Roles for this course'
            placeholder='Search roles...'
            name='companyRoleIds'
            value={null}
            modifiers={['expandable']}
            options={resourcesAsOptions({
              resourcesCollection: selectableRoles,
              resourceIdentifier: 'position'
            })}
            externalProps={{additionalNoOptionsMessage: 'New company roles must be added from the course register.'}}
            onChange={ (e) => onCourseRolesOptionChange(e.value.toString()) }
          />

          { currentActor.isAllowedFeature('training_register') &&
            <>
              <div className='popup__body-form p-0 m-b-32'>
                <div className='form-container'>
                  {orderedCourseCompanyRoles.map((courseCompanyRole) => {
                    return <div key={courseCompanyRole.roleId} className="tw-group/pill tw-rounded-md tw-bg-grey-050 chip-item-pill w-100 py-10 px-12 m-b-8 tw-flex tw-relative tw-justify-between tw-items-center">
                      <div className="tw-text-s tw-font-medium tw-tracking-wide">
                        {courseCompanyRole.rolePosition}
                      </div>

                      <div className='tw-flex tw-items-center'>
                        <div className='tw-w-28'>
                          <CollectionSelect
                            modifiers={['pill']}
                            name='requiredCCR'
                            isSearchable={false}
                            value={courseCompanyRole.required ? {value: true, label: 'Required'} : {value: false, label: 'Optional'}}
                            options={[{value: true, label: 'Required'}, {value: false, label: 'Optional'}]}
                            onChange={e => onCourseCompanyRoleRequiredChange(courseCompanyRole.roleId, e.value)}
                          />
                        </div>
                        <span onClick={() => onCourseCompanyRoleDelete(courseCompanyRole.roleId)} className="collection-select__multivalue-remove m-l-8 circle--remove tw-bg-transparent hover:tw-bg-red-600 before:tw-content-[''] before:tw-bg-grey-700 hover:before:tw-bg-white after:tw-content-[''] after:tw-bg-grey-700 hover:after:tw-bg-white"/>
                      </div>
                    </div>;
                  })}
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0'/>

      <div className='popup__body-form p-t-40 m-b-32'>
        {isELearningCustom ? (
          <>
            <label className='field__label tw-font-medium tw-mb-4'>
              Custom eLearning
              <Tooltip className='tooltip-dark--max-w-xxs' placement='top' trigger='hover' tooltip='Speak to your account manager to remove or make edits' >
                <CircleQuestion />
              </Tooltip>
            </label>
            <CustomELearningCourseCard
              courseName={currentCourse.name}
              courseProvider={currentCourse.provider}
              courseDuration={eLearningCourse.attributes.duration}
              courseCost={displayCurrencyString({ string: eLearningCourseCost, currencyCode })}
            />
            {currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register']) &&
              <CourseSidePanelAutoEnrolmentForm
                hasAutoEnrolBeenActivelySelected={currentCourse.hasAutoEnrolBeenActivelySelected}
                autoEnrolEnrolleeScope={currentCourse.autoEnrolEnrolleeScope}
                autoEnrolCourseRequirementScope={currentCourse.autoEnrolCourseRequirementScope}
                isExpiringCourse={currentCourse.expires}
                onInputChange={onInputChange}
                onOptionChange={onOptionChange}
              />
            }
          </>
        ) : (
          <CourseSidePanelProviderForm
            course={currentCourse}
            requestError={requestError}
            removeErrorStyling={removeErrorStyling}
            onInputChange={onInputChange}
            onOptionChange={onOptionChange}
            onELearningProviderSelectedChange={onELearningProviderSelectedChange}
            bodyRef={bodyRef}
          />
        )}
      </div>
    </React.Fragment>
  )
}

CourseSidePanelForm.propTypes = {
  currentCourse: PropTypes.object.isRequired,
  eLearningCourse: resourceShape('eLearningCourse'),
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onELearningProviderSelectedChange: PropTypes.func.isRequired,
  assignableRoles: PropTypes.array.isRequired,
  bodyRef: PropTypes.object.isRequired
}
