import React from 'react';
import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';

export default function SubcontractorsForm(props) {

  return (
    <div className='popup__body-form'>
      <ErrorMessage wrapperClassName='form-container' validationErrors={props.requestError.validationErrors} isFallback={props.requestError.isFallback}/>
      <div className='form-container'>
        <TextField autoFocus={true} inputRef={props.sidePanelFieldRef} label='Sub-contractor company' name='name' value={props.currentSubcontractor.name} isRequired={true} fieldError={props.requestError.validationErrors.name} removeErrorStyling={props.removeErrorStyling} onChange={props.onSubcontractorInputChange}/>
      </div>
    </div>
  )
}
