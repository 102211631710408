import React from "react";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";
import { personDisplayName } from "components/helpers/users";

import TextButton from "components/application/buttons/TextButton";

export default function SupportModalContent({
  lineManager,
  closeModal
}) {
  return (
    <>
      <div className="tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey-100 tw-p-6">
        <h2 className="tw-text-l tw-text-grey-900 tw-font-semibold tw-m-0">
          Support
        </h2>
      </div>
      <div className="tw-p-6">
        {lineManager?.attributes?.email && (
          <>
            <p className="tw-font-semibold tw-mb-0">Spotted something incorrect?</p>
            <p>
              We recommend reaching out to {personDisplayName(lineManager.attributes)} <span className="tw-whitespace-nowrap"><span>(</span><a className="app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300" href={`mailto:${lineManager.attributes.email}`}>{lineManager.attributes.email}</a><span>)</span></span> who will be able to help.
            </p>
          </>
        )}
        <p className="tw-font-semibold tw-mb-0">Having technical issues?</p>
        <p className="tw-mb-0">For help with your training profile, please speak to your training manager or <a className="app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300" target="blank" href="https://intercom.help/hands-hq/en/articles/10213935-new-viewing-your-training-profile">read our guide</a>.</p>
      </div>
      <div className="tw-flex tw-justify-end tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-p-4">
        <TextButton size="md" color="blue" onClick={closeModal}>
          Close
        </TextButton>
      </div>
    </>
  )
}

SupportModalContent.propTypes = {
  lineManager: resourceShape('lineManager'),
  closeModal: PropTypes.func.isRequired
}
