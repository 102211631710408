import moment from 'moment';

export const autoEnrolmentConfigurationParamsForCreate = ({ currentCourse, currentContext }) => {
  const {
    hasAutoEnrolBeenActivelySelected,
    autoEnrolEnrolleeScope,
    autoEnrolCourseRequirementScope,
    isELearningProviderSelected,
    expires: isCourseExpirySelected
  } = currentCourse

  const isEligibleCourseExpirySelection = currentContext.isAutoEnrolmentIncludingMissingTraining || isCourseExpirySelected;

  const params = hasAutoEnrolBeenActivelySelected && isELearningProviderSelected && isEligibleCourseExpirySelection && {
    enrollee_scope: autoEnrolEnrolleeScope,
    course_requirement_scope: autoEnrolCourseRequirementScope
  };

  // Explicitly returning null to prevent backend errors when submitting params
  return params || null
};

export const autoEnrolmentConfigurationParamsForUpdate = ({ currentCourse, domainAutoEnrolmentConfiguration, currentContext }) => {
  const {
    hasAutoEnrolBeenActivelySelected,
    autoEnrolEnrolleeScope,
    autoEnrolCourseRequirementScope,
    isELearningProviderSelected,
    expires: isCourseExpirySelected
  } = currentCourse;

  const domainAutoEnrolmentConfigurationId = domainAutoEnrolmentConfiguration?.id;
  const isAutoEnrolmentIncludingMissingTraining = currentContext.isAutoEnrolmentIncludingMissingTraining

  const actionRequired = (function determineActionRequired() {
    if ((function shouldDelete() {
      return domainAutoEnrolmentConfigurationId && (
        !hasAutoEnrolBeenActivelySelected ||
        (!isAutoEnrolmentIncludingMissingTraining && !isCourseExpirySelected) ||
        !isELearningProviderSelected
      )
    })()) {
      return 'delete';
    } else if ((function shouldCreateOrUpdate() {
      return hasAutoEnrolBeenActivelySelected &&
        (isAutoEnrolmentIncludingMissingTraining || isCourseExpirySelected) &&
        isELearningProviderSelected
    })()) {
      return !!domainAutoEnrolmentConfigurationId ? 'update' : 'create';
    }
  })();

  const params = (function determineParams() {
    const attributes = { enrollee_scope: autoEnrolEnrolleeScope, course_requirement_scope: autoEnrolCourseRequirementScope };

    switch (actionRequired) {
      case 'delete':
        return { id: domainAutoEnrolmentConfigurationId, _destroy: 1 };
      case 'create':
        return attributes;
      case 'update':
        return { ...attributes, id: domainAutoEnrolmentConfigurationId };
      default:
        return null;
    }
  })()

  return params
};

export const mappedAutoEnrolmentExclusionsByConfigurationId = (autoEnrolmentExclusions) => {
  return Object.fromEntries(
    Object.values(autoEnrolmentExclusions).map(value => [
      value.relationships.autoEnrolmentConfiguration.data.id,
      value
    ])
  );
};

export const prioritisedAutoEnrolmentBlockers = autoEnrolmentDependencies => {
  const { isELearningNonCustom,
    isCreditInfoUnavailable,
    isPersonnelEmailUnavailable,
    currentELearningCredits,
    isAutoEnrolmentIncludingMissingTraining,
    training,
    isBookingAllowedWithinTrackedPersonnelLimits,
    autoEnrolmentConfiguration,
    isExpiringCourse,
    isSubcontractor,
    isRequired } = autoEnrolmentDependencies;

  const blockers = [];

  if (!autoEnrolmentConfiguration) blockers.push('noAutoEnrolmentConfiguration');
  if (!isBookingAllowedWithinTrackedPersonnelLimits) blockers.push('trackedPersonnelLimit')

  if (isELearningNonCustom && isCreditInfoUnavailable) blockers.push('creditInfoUnavailable')
  if (isPersonnelEmailUnavailable) blockers.push('personnelEmailUnavailable')
  if (isELearningNonCustom && !isCreditInfoUnavailable && currentELearningCredits <= 0) blockers.push('insufficientCredits');


  const isAnEligibleTrainingInclusion = isEligibleTrainingInclusion({ isAutoEnrolmentIncludingMissingTraining, training });
  if (!isAnEligibleTrainingInclusion) blockers.push('invalidTrainingInclusion');

  if (autoEnrolmentConfiguration && !isEligibleEmployeeScope({ autoEnrolmentConfiguration, isSubcontractor })) blockers.push('enrolleeScope');
  if (autoEnrolmentConfiguration && !isEligibleCourseRequirementScope({ autoEnrolmentConfiguration, isRequired })) blockers.push('courseRequirementScope');

  if (!isEligibleCourseExpiry({ isAutoEnrolmentIncludingMissingTraining, training, isExpiringCourse })) blockers.push('invalidCourseExpiry');
  if (isAnEligibleTrainingInclusion && !isEligibleTrainingExpiryDate({ isAutoEnrolmentIncludingMissingTraining, training })) blockers.push('invalidTrainingExpiryDate');

  return blockers
};

export const hasOnlyNonCriticalBlockers = ({ prioritisedAutoEnrolmentBlockers }) => {
  if (prioritisedAutoEnrolmentBlockers.length === 0) return false

  const nonCriticalBlockers = ['creditInfoUnavailable', 'personnelEmailUnavailable', 'insufficientCredits'];

  return prioritisedAutoEnrolmentBlockers.every(blocker => nonCriticalBlockers.includes(blocker))
};

export const autoEnrolmentNonCriticalBlockersStatusText = ({ priorityBlocker, latestTraining, courseExpiringDuration }) => {
  if (priorityBlocker === 'creditInfoUnavailable') return 'There was a problem calculating the available credits so the course will not be auto-enrolled';
  if (priorityBlocker === 'personnelEmailUnavailable') return 'Personnel must have an email address to be auto-enrolled on eLearning';

  const proposedEnrolmentDate = proposedAutoEnrolmentDate({ latestTraining, courseExpiringDuration });

  if (proposedEnrolmentDate) {
    const isProposedEnrolmentDateTomorrow = proposedEnrolmentDate?.isSame(moment().clone().add(1, 'days'));

    if (priorityBlocker === 'insufficientCredits') return `Enrolment will be attempted ${isProposedEnrolmentDateTomorrow ? 'tomorrow' : `on ${proposedEnrolmentDate.format('D MMM YY')}`} but there is currently not enough credits`;
    if (!priorityBlocker) return `Course will auto-enrol ${isProposedEnrolmentDateTomorrow ? 'tomorrow' : `on ${proposedEnrolmentDate.format('D MMM YY')}`}`;
  }

  return ''
};

// private

const proposedAutoEnrolmentDate = ({ latestTraining, courseExpiringDuration }) => {
  const dateToday = moment().clone();
  const dateTomorrow = dateToday.add(1, 'days');

  if (!latestTraining) { return dateTomorrow };

  const proposedAutoEnrolmentDate = moment(latestTraining.attributes.expiryDate).clone().subtract(courseExpiringDuration);

  return proposedAutoEnrolmentDate.isAfter(dateToday) ? proposedAutoEnrolmentDate : dateTomorrow
};

const isEligibleTrainingInclusion = ({ isAutoEnrolmentIncludingMissingTraining, training }) => {
  return !!(training || (isAutoEnrolmentIncludingMissingTraining && !training))
};

const isEligibleEmployeeScope = ({ autoEnrolmentConfiguration, isSubcontractor }) => {
  return !!(autoEnrolmentConfiguration?.attributes.enrolleeScope === 'all' || (autoEnrolmentConfiguration?.attributes.enrolleeScope === 'employees' && !isSubcontractor))
};

const isEligibleCourseRequirementScope = ({ autoEnrolmentConfiguration, isRequired }) => {
  return !!(autoEnrolmentConfiguration?.attributes.courseRequirementScope === 'all' || (autoEnrolmentConfiguration?.attributes.courseRequirementScope === 'required' && isRequired))
};

const isEligibleCourseExpiry = ({ isAutoEnrolmentIncludingMissingTraining, training, isExpiringCourse }) => {
  return isExpiringCourse || (isAutoEnrolmentIncludingMissingTraining && !training)
};

const isEligibleTrainingExpiryDate = ({ isAutoEnrolmentIncludingMissingTraining, training }) => {
  return training?.attributes?.expiryDate || (isAutoEnrolmentIncludingMissingTraining && !training)
};
