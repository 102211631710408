import React, { useState } from "react";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";
import { useInView } from "react-intersection-observer";

import { useBreadBoard } from "components/contexts/Toaster";
import { useInfinitePersonnelSubordinates } from "components/mobileTrainingProfile/hooks/reactQuery";
import { formatResources } from "components/mobileTrainingProfile/helpers/requests";
import { personDisplayName } from "components/helpers/users";

const moreBreakpoint = 3;

export default function ManagementTab({
  personnelId,
  lineManager
}) {
  const breadBoard = useBreadBoard();

  const [loadMoreRef, loadMoreInView] = useInView();

  const {
    data: personnelSubordinatesQuery,
    isLoading: isPersonnelSubordinatesQueryLoading,
    isError: isPersonnelSubordinatesQueryError,
    isFetching: isFetchingPersonnelSubordinates,
    fetchNextPage: fetchNextPageOfPersonnelSubordinates,
    hasNextPage: hasNextPageOfPersonnelSubordinates
  } = useInfinitePersonnelSubordinates({
    personnelId,
    select: personnelSubordinatesQuerySelector,
    onError: breadBoard.addInedibleToast
  });

  const [showAllSubordinates, setShowAllSubordinates] = useState(false);

  const allSubordinates = (isPersonnelSubordinatesQueryLoading || isPersonnelSubordinatesQueryError) ? [] : personnelSubordinatesQuery.subordinates.resources;
  const visibleSubordinates = showAllSubordinates ? allSubordinates : allSubordinates.slice(0, moreBreakpoint);

  if (loadMoreInView && !isFetchingPersonnelSubordinates && hasNextPageOfPersonnelSubordinates) fetchNextPageOfPersonnelSubordinates()

  return (
    <div className="tw-border-solid tw-border-1 tw-rounded-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-py-6">
      <div className="tw-mb-6">
        <div className="tw-font-semibold tw-mb-2">Line manager</div>
        {lineManager ? (
          <div className="tw-inline-block tw-py-1.5 tw-px-4 tw-rounded-[16px] tw-break-words tw-bg-grey-050">
            {personDisplayName(lineManager.attributes)}
          </div>
        ) : (
          <div className="tw-text-grey-500">Not set</div>
        )}
      </div>
      <div>
        <div className="tw-font-semibold tw-mb-2">Reports</div>
        {!isPersonnelSubordinatesQueryLoading && (
          allSubordinates.length > 0 ? (
            <>
              <ul className="tw-list-none tw-m-0 tw-p-0">
                {visibleSubordinates.map((subordinate) => (
                  <li className="tw-mb-2 last:tw-mb-0" key={subordinate.id}>
                    <span className="tw-inline-block tw-py-1.5 tw-px-4 tw-rounded-[16px] tw-break-words tw-bg-grey-050">
                      {personDisplayName(subordinate.attributes)}
                    </span>
                  </li>
                ))}
                {showAllSubordinates && !isFetchingPersonnelSubordinates && hasNextPageOfPersonnelSubordinates && (
                  <li ref={loadMoreRef}>
                    Loading subordinates...
                  </li>
                )}
              </ul>
              {!showAllSubordinates && allSubordinates.length > moreBreakpoint && (
                <div className='flex flex--vertically-centered tw-mt-4'>
                  <button
                    className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                    onClick={() => setShowAllSubordinates(true)}
                  >
                    Show all ({personnelSubordinatesQuery.meta.totalCount - moreBreakpoint} more)
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="tw-text-grey-500">Not set</div>
          )
        )}
      </div>
    </div>
  )
}

ManagementTab.propTypes = {
  personnelId: PropTypes.string.isRequired,
  lineManager: resourceShape('lineManager')
}

const personnelSubordinatesQuerySelector = (response) => {
  const data = response.pages.map(page => page.data).flat();

  return {
    subordinates: formatResources({ resources: data, options: { resources: true } }),
    meta: response.pages[response.pages.length - 1].meta
  }
}
