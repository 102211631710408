import React from 'react';
import PropTypes from 'prop-types';

import { getBookingDate, checkIsDateReached } from 'components/helpers/resources/bookings';

import ActionsPill from 'components/application/ActionsPill';

import CalendarIcon from '-!svg-react-loader?name=CalendarIcon!icons/calendar.svg';

const bookingTooltipOptions = {
  expiring: 'Training is expiring soon and requires booking',
  expired: 'Training is expired and requires booking',
  missing: 'Training is missing and requires booking',
  booked: 'Training is booked',
  dateReached: 'Please record the new training'
};

export default function TrainingBookings(props) {
  const {
    booking,
    course,
    currentTrainingStatus,
    isBookingTooltipDisplayable,
    isEligibleForManualBooking,
    onShowBooking,
    onAddBooking
  } = props;

  const bookingDate = getBookingDate(booking);
  const isDateReached = checkIsDateReached(booking);
  const bookingTooltipKey = booking ? (isDateReached ? 'dateReached': 'booked') : currentTrainingStatus;

  if (booking) return (
    <ActionsPill
      color={!isDateReached ? 'green' : 'cyan'}
      displayText={`Booked for ${bookingDate?.format('D MMM YYYY')}`}
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={isBookingTooltipDisplayable ? bookingTooltipOptions[bookingTooltipKey] : undefined}
      onClick={() => onShowBooking(booking.id)}
    />
  )

  if (isEligibleForManualBooking) return (
    <ActionsPill
      color='cyan'
      displayText='Book training'
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={bookingTooltipOptions[bookingTooltipKey]}
      onClick={() => onAddBooking({ course: course })}
    />
  )

  return null
}

TrainingBookings.propTypes = {
  booking: PropTypes.object,
  course: PropTypes.object,
  currentTrainingStatus: PropTypes.string,
  isBookingTooltipDisplayable: PropTypes.bool,
  isEligibleForManualBooking: PropTypes.bool,
  onShowBooking: PropTypes.func,
  onAddBooking: PropTypes.func.isRequired,
}
