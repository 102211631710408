import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { titleize } from 'components/helpers/strings';
import { userFormParams } from 'components/helpers/users';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import UserDetailsEditor from 'components/users/UserDetailsEditor';
import SearchImportablePersonnelField from 'components/users/SearchImportablePersonnelField';
import FormFooter from 'components/sidepanels/FormFooter';
import CheckboxField from 'components/application/CheckboxField';
import useUserForm from 'components/users/hooks/useUserForm';
import useRequestError from 'components/hooks/useRequestError';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import UserPermissionsAndDivisionAccessInfoCard from 'components/users/cards/UserPermissionsAndDivisionAccessInfoCard';
import TeamsAccessExplanationNotification from 'components/users/TeamsAccessExplanationNotification';

export default function UserSidePanel({ defaultUser, isPermissionsEditable, selectedUserId, currentUser, isOpen, onClose, divisionName, divisionId, onCreate, onUpdate, context, assignablePermissions, isCourseApplicableResource, projectDisplayNameSingular, isTeamsAllowed }) {
  const currentActorContext = useCurrentActor();
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const { formUser, setFormUser, resetUserForm } = useUserForm({ seededUser: defaultUser, seedType: 'form' });

  useEffect(() => {
    setFormUser(defaultUser)
  }, [isOpen])

  const isUserEmail = (inputValue) => {
    return defaultUser && inputValue === defaultUser.email
  }
  const handlePersonnelSelection = (option) => {
    const selectedAttributes = option.__isNew__ || option.isNotDerived ? { isDerived: false, email: option.value } : { isDerived: true, ...option.value.attributes };
    resetRequestError()
    setFormUser({ ...formUser, isSelected: true, ...selectedAttributes })
  }
  const handleSelectionReset = (options = { emailOnly: false }) => {
    const resettingAttributes = options.emailOnly ? { email: '' } : { email: '', firstName: '', lastName: '' };

    setFormUser({ ...formUser, isDerived: false, isSelected: true, ...resettingAttributes })
  }
  const handleSelectionEdit = () => setFormUser({ ...formUser, isDerived: false })
  const handleDetailsChange = (changeOptions) => setFormUser({ ...formUser, [changeOptions.attribute]: changeOptions.value })
  const isPrimaryUserViewingSelf = currentUser.attributes.accessType === 'primary' && selectedUserId === currentUser.id
  const isViewingAccountAdmin = defaultUser && defaultUser.accessType === 'administrator'
  const isSuperUser = currentUser.attributes.accessType === 'primary' || currentUser.attributes.accessType === 'administrator'

  const handleSubmit = () => {
    setButtonDisabled(true)
    const promise = context === 'new' ? createPromise() : updatePromise();
    promise
      .then(() => {
        resetUserForm()
        setButtonDisabled(false)
      })
      .catch((e) => {
        handleRequestError(e)
        setButtonDisabled(false)
      })
  }

  const createPromise = () => (
    axios
      .post('/users/creations', userFormParams(formUser, divisionId, isPermissionsEditable, currentUser))
      .then((response) => onCreate(response.data))
  )

  const updatePromise = () => (
    axios
      .patch(`/users/${selectedUserId}`, userFormParams(formUser, divisionId, isPermissionsEditable, currentUser))
      .then((response) => onUpdate(response.data))
  )

  const handleClose = () => {
    resetRequestError()
    onClose()
  }

  const noPermissonsReason = () => {
    if (isPrimaryUserViewingSelf) {
      return (
        <>
          <div className='m-b-4 tw-text-grey-900'>You are the account owner</div>
          <div className='tw-text-s tw-font-normal tw-tracking-wide'>To change the account owner, please contact the customer success team.</div>
        </>
      )
    } else if (isViewingAccountAdmin) {
      return (
        <>
          <div className='m-b-4 tw-text-grey-900'>This user is an account administrator</div>
          <div className='tw-text-s tw-font-normal tw-tracking-wide'>To downgrade their permissions, please contact the customer success team.</div>
        </>
      )
    }
  }

  function checkboxCheckedStatus(accessAttribute, formUserStatus) {
    if (!formUser) return false;

    switch (accessAttribute) {
      case 'administrator':
        return formUser && formUserStatus['administrator'];
      case 'rolesAndCoursesEditor':
        return formUserStatus['rolesAndCoursesEditor'];
      case 'personnelViewer':
        return formUserStatus['personnelViewer'] || formUserStatus['personnelEditor'] || formUserStatus['administrator'];
      default:
        return formUserStatus[accessAttribute] || formUserStatus['administrator'];
    }
  }

  function checkboxDisabledStatus(accessAttribute, formUserStatus) {
    if (!formUser) return false;

    switch (accessAttribute) {
      case 'administrator':
        return selectedUserId === currentUser.id;
      case 'personnelViewer':
        return formUserStatus['personnelEditor'] || formUserStatus['administrator'];
      case 'rolesAndCoursesEditor':
        return !isSuperUser;
      default:
        return formUserStatus['administrator'];
    }
  }

  const tooltipProps = (accessAttribute) => {
    const tooltipProps = { tooltipType: 'hover', tooltipDelay: 500 }

    switch (true) {
      case ('rolesAndCoursesEditor' === accessAttribute && isSuperUser):
        tooltipProps.tooltip = `Can add, delete or modify roles${isCourseApplicableResource ? ' and courses' : ''}`
        break;
      case ('rolesAndCoursesEditor' === accessAttribute && !isSuperUser):
        tooltipProps.tooltip = `This permission may only be set by account administrators as it lets users delete or modify roles${isCourseApplicableResource ? ' and courses' : ''} across all divisions`
        break;
      case ('administrator' === accessAttribute) && (selectedUserId === currentUser.id):
        tooltipProps.tooltip = 'You cannot downgrade your own access and permissions'
        break;
      case ('administrator' === accessAttribute):
        tooltipProps.tooltip = 'Has full access, can add, delete or modify users and assign them permissions'
        break;
      case (formUser && formUser['administrator']):
        tooltipProps.tooltip = 'Administrators have all division based permissions'
        break;
      case ('projectEditor' === accessAttribute):
        tooltipProps.tooltip = 'All users with access to a division can edit projects'
        break;
      case ('projectApprover' === accessAttribute):
        tooltipProps.tooltip = 'Can approve, reject or conditionally approve projects'
        break;
      case ('contentEditor' === accessAttribute):
        tooltipProps.tooltip = 'Can make changes to the content library'
        break;
      case ('personnelEditor' === accessAttribute && isCourseApplicableResource):
        tooltipProps.tooltip = 'Can manage personnel and their training'
        break;
      case ('personnelEditor' === accessAttribute):
        tooltipProps.tooltip = 'Can edit personnel details'
        break;
      case ('personnelViewer' === accessAttribute && formUser && formUser['personnelEditor']):
        tooltipProps.tooltip = 'All users with permissions to edit personnel can view them'
        break;
      case ('personnelViewer' === accessAttribute):
        tooltipProps.tooltip = 'Can view personnel and their details'
        break;
    }
    return tooltipProps
  }

  const header = context === 'new' ? (
    <div className='popup__title popup__title--tertiary-split'>
      <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>Add user to</h1>
      <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>{divisionName}</h2>
    </div>
  ) : (
    <div className='popup__title popup__title--tertiary'>
      <h2 className='truncated-text-container tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>Edit user</h2>
    </div>
  );

  const body = (
    <div>
      <div className='popup__body-form p-t-0'>
        <div className='m-t-32 m-b-24'>
          {currentActorContext.isSamlSsoAllowed && currentActorContext.identityProvider && context === 'new' && (
            <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-b-24'>
              <div className='m-r-12 fh-20'>
                <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
              </div>
              <p className='m-0'>
                <span>The user must also be added in your identity provider </span>
                <span className='tw-font-semibold'>({titleize(currentActorContext.identityProvider.attributes.name)})</span>
                <span> for them to access HandsHQ.</span>
              </p>
            </div>
          )}
          {Object.keys(requestError.validationErrors).length > 0 && (
            <div className='m-b-24'>
              <ErrorMessage modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide' validationErrors={requestError.validationErrors} isFallback={requestError.isFallback} />
            </div>
          )}
          <h3 className='tw-text-l tw-font-semibold tw-tracking-auto'>User details</h3>
        </div>
        {(!formUser || (formUser && !formUser.email)) && (
          <div className={`form-group${requestError.validationErrors.email && requestError.validationErrors.email.fieldHighlighted ? ' collection-select--invalid' : ''}`}>
            <SearchImportablePersonnelField onPersonnelSelection={handlePersonnelSelection} isUserEmail={defaultUser && isUserEmail} removeErrorStyling={removeErrorStyling} />
          </div>
        )}
        {formUser && formUser.isSelected && (
          <UserDetailsEditor
            formUser={formUser}
            formContext={context}
            requestError={requestError}
            removeErrorStyling={removeErrorStyling}
            onSelectionEdit={handleSelectionEdit}
            onSelectionReset={handleSelectionReset}
            onDetailsChange={handleDetailsChange}
          />)
        }
        <hr className='m-t-32 m-b-32' />
        <h3 className='tw-text-l tw-font-semibold tw-tracking-auto m-t-0 m-b-24'>Permissions</h3>
        <div className='m-b-24'>
          <UserPermissionsAndDivisionAccessInfoCard />
        </div>
        {isTeamsAllowed && <TeamsAccessExplanationNotification />}
        {isPrimaryUserViewingSelf || isViewingAccountAdmin ? (
          noPermissonsReason()
        ) : (
          <ul className='checkbox-collection list-unstyled'>
            <li className='checkbox-collection__item'>
              <CheckboxField
                name={'user_administrator'}
                label={'Administrator'}
                checked={checkboxCheckedStatus('administrator', formUser)}
                disabled={checkboxDisabledStatus('administrator', formUser)}
                onChange={(e) => setFormUser({ ...formUser, administrator: e.target.checked })}
                labelTextProps={{ className: 'tw-font-medium' }}
                {...tooltipProps('administrator')}
              />
            </li>
            <li className='checkbox-collection__item'>
              <CheckboxField
                name={'user_roles_and_courses_editor'}
                label={`Roles${isCourseApplicableResource ? ' & courses' : ''} editor`}
                checked={checkboxCheckedStatus('rolesAndCoursesEditor', formUser)}
                disabled={checkboxDisabledStatus('rolesAndCoursesEditor', formUser)}
                onChange={(e) => setFormUser({ ...formUser, rolesAndCoursesEditor: e.target.checked })}
                labelProps={{ className: isSuperUser ? '' : 'hhq-checkbox__container--disabled-inactive-filled' }}
                labelTextProps={{ className: 'tw-font-medium' }}
                {...tooltipProps('rolesAndCoursesEditor')}
              />
            </li>
            {assignablePermissions.includes('projectEditor') &&
              <li className='checkbox-collection__item'>
                <CheckboxField
                  name={'user_project_editor'}
                  label={`${projectDisplayNameSingular} editor`}
                  onChange={() => void(0)}
                  checked={true}
                  disabled={true}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  {...tooltipProps('projectEditor')}
                />
              </li>
            }
            {assignablePermissions.includes('projectApprover') &&
              <li className='checkbox-collection__item'>
                <CheckboxField
                  name={'user_project_approver'}
                  label={`${projectDisplayNameSingular} approver`}
                  checked={checkboxCheckedStatus('projectApprover', formUser)}
                  disabled={checkboxDisabledStatus('projectApprover', formUser)}
                  onChange={(e) => setFormUser({ ...formUser, projectApprover: e.target.checked })}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  {...tooltipProps('projectApprover')}
                />
              </li>
            }
            {assignablePermissions.includes('contentEditor') &&
              <li className='checkbox-collection__item'>
                <CheckboxField
                  name={'user_content_editor'}
                  label={'Content editor'}
                  checked={checkboxCheckedStatus('contentEditor', formUser)}
                  disabled={checkboxDisabledStatus('contentEditor', formUser)}
                  onChange={(e) => setFormUser({ ...formUser, contentEditor: e.target.checked })}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  {...tooltipProps('contentEditor')}
                />
              </li>
            }
            <li className='checkbox-collection__item'>
              <CheckboxField
                name={'user_personnel_editor'}
                label={'Personnel editor'}
                checked={checkboxCheckedStatus('personnelEditor', formUser)}
                disabled={checkboxDisabledStatus('personnelEditor', formUser)}
                onChange={(e) => setFormUser({ ...formUser, personnelEditor: e.target.checked })}
                labelTextProps={{ className: 'tw-font-medium' }}
                {...tooltipProps('personnelEditor')}
              />
            </li>
            <li className='checkbox-collection__item'>
              <CheckboxField
                name={'user_personnel_viewer'}
                label={'Personnel viewer'}
                checked={checkboxCheckedStatus('personnelViewer', formUser)}
                disabled={checkboxDisabledStatus('personnelViewer', formUser)}
                onChange={(e) => setFormUser({ ...formUser, personnelViewer: e.target.checked })}
                labelTextProps={{ className: 'tw-font-medium' }}
                {...tooltipProps('personnelViewer')}
              />
            </li>
          </ul>
        )}
      </div>
    </div>
  )

  const footer = (
    <FormFooter
      text={context === 'new' ? 'Add user' : 'Save changes'}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitButtonDisabled={submitDisabled || buttonDisabled}
    />
  );

  return (
    <SidePanel
      isOpen={isOpen}
      closeCallback={handleClose}
      color='tertiary'
      headerContent={header}
      bodyContent={body}
      footerContent={footer}
    />
  )
}

UserSidePanel.propTypes = {
  context: PropTypes.string.isRequired,
  defaultUser: PropTypes.object,
  isPermissionsEditable: PropTypes.bool,
  selectedUserId: PropTypes.string,
  isOpen: PropTypes.bool,
  divisionName: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  assignablePermissions: PropTypes.array.isRequired,
  isCourseApplicableResource: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  projectDisplayNameSingular: PropTypes.string.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired
}
