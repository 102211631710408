import React from "react";
import moment from "moment";

import { resourceShape } from "components/helpers/serialisableResources";
import { useCurrentActor } from "components/contexts/CurrentActor";
import { personDisplayName } from "components/helpers/users";

import DesktopMobileIcon from "-!svg-react-loader?name=DesktopMobileIcon!icons/desktop-mobile.svg";
import LaunchIcon from "-!svg-react-loader?name=LaunchIcon!icons/launch.svg";
import CalendarIcon from "-!svg-react-loader?name=CalendarIcon!icons/calendar.svg";

import OutlinedButton from "components/application/buttons/OutlinedButton";

export default function BookingDetails({
  course,
  booking,
  booker,
  registration
}) {
  const currentActor = useCurrentActor();

  const courseHasELearningCourse = currentActor.isAllowedFeature("e_learning") && !!course.relationships.eLearningCourse?.data?.id;
  const formattedBookingDate = (booking.attributes.date ? moment.parseZone(booking.attributes.date) : null)?.format("D MMM YYYY");

  return (
    <div className="tw-w-full tw-bg-cyan-025 tw-text-cyan-800 tw-rounded-xl tw-p-4">
      {courseHasELearningCourse ? (
        <>
          <div className="tw-inline-flex tw-text-m tw-font-semibold">
            <DesktopMobileIcon className="tw-mr-1" height={20} width={20} />
            <span>You need to complete eLearning</span>
          </div>
          {booker && (
            <div className="tw-mt-2">
              <p className="tw-mb-0">Booked by {personDisplayName(booker.attributes)}</p>
              <a className="app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300" href={`mailto:${booker.attributes.email}`}>{booker.attributes.email}</a>
            </div>
          )}
          <div className="tw-mt-4">
            <OutlinedButton size="sm" color="grey" onClick={() => window.open(`/e_learning/bookings/${booking.attributes.hashid}`, "_blank")}>
              <span>{`${registration ? "Continue" : "Start"} course`}</span>
              <LaunchIcon className="tw-ml-1 -tw-mr-1" height={20} width={20} />
            </OutlinedButton>
          </div>
        </>
      ) : (
        <>
          <div className="tw-inline-flex tw-text-m tw-font-semibold">
            <CalendarIcon className="tw-mr-1" height={20} width={20} />
            <span>Course booked for {formattedBookingDate}</span>
          </div>
          {booking.attributes.notes && (
            <div className="tw-mt-2">{booking.attributes.notes}</div>
          )}
          {booker && (
            <div className="tw-mt-2">
              <p className="tw-mb-0">Booked by {personDisplayName(booker.attributes)}</p>
              <a className="app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300" href={`mailto:${booker.attributes.email}`}>{booker.attributes.email}</a>
            </div>
          )}
        </>
      )}
    </div>
  )
}

BookingDetails.propTypes = {
  course: resourceShape("course").isRequired,
  booking: resourceShape("booking").isRequired,
  booker: resourceShape("simpleUser"),
  registration: resourceShape("registration")
}
