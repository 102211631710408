import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { enableAllPlugins } from "immer";
enableAllPlugins()
import { produce } from "immer";

import { findAssignedCompany } from 'components/helpers/companies';
import { resourceShape } from 'components/helpers/serialisableResources';

import PersonnelCustomFieldsForm from 'components/personnel/PersonnelCustomFieldsForm';
import SelectSubcontractor from 'components/personnel/SelectSubcontractor';
import SelectDivision from 'components/personnel/SelectDivision';
import SelectCompanyRoles from 'components/personnel/SelectCompanyRoles';
import UserCompanyRolesList from 'components/personnel/UserCompanyRolesList';
import SelectLineManager from 'components/personnel/SelectLineManager';
import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import RadioField from 'components/application/RadioField';
import Switch from 'components/application/Switch';
import Tooltip from 'components/application/Tooltip';

import CircleQuestion from 'components/application/CircleQuestion';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

const profileAccessToggleDisabledTooltip = {
  appUserPresent: <p className='tw-m-0'>Personnel is already a user</p>,
  emailNotPresent: <p className='tw-m-0'>An email address is<br/>required for the employee</p>
};

export default function PersonnelForm(props) {
  const {
    onNewCompanyRoleAdd,
    onAssignableRoleAdd,
    assignableCompanies,
    assignableRoles,
    availableFields,
    availableFieldOptions,
    currentPersonnel,
    user,
    onCompanyRolesOptionChange,
    onDateFieldChange,
    onToggle,
    onFieldOptionChange,
    onPersonnelInputChange,
    onPersonnelOptionChange,
    onPersonnelFieldChange,
    onRemoveRole,
    onSelectPrimary,
    isCreatableCompanyRole,
    isEditableProfileAccess,
    isSubcontractor,
    lineManagers,
    personnel,
    removeErrorStyling,
    requestError,
    onSubcontractorSelect,
    setIsSubcontractor,
    sidePanelContext,
    sidePanelFieldRef,
    currentSubcontractor,
    setCurrentPersonnel,
  } = props;

  const assignedDivision = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    companyId: currentPersonnel.company.companyId
  });

  const assignedSubcontractor = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    subcontractorId: currentPersonnel.company.subcontractorId
  });

  const primaryDivision = assignableCompanies.find(company => company.attributes.resourceType === 'Company' && company.attributes.primary);
  const subcontractors = assignableCompanies.filter(company => company.attributes.resourceType === 'Subcontractor')
  const companies = assignableCompanies.filter(company => company.attributes.resourceType === 'Company');

  const focusableFields = ['firstName', 'lastName', 'externalId', 'email']

  const firstEmptyField = (
    focusableFields.find(field => !currentPersonnel[field])
  )

  const isPersonnelUserPresent = user && user.attributes.accessType === 'personnel';
  const isAppUserPresent = user && user.attributes.accessType !== 'personnel';

  const isDivisionTeamWarningVisible = !!personnel?.relationships?.teams && personnel.relationships.teams.data.length > 0 && currentPersonnel.company.companyId != personnel.relationships.company.data.id;
  const isSubcontractorProfileWarningVisible = (!user || isPersonnelUserPresent) && currentPersonnel.profileAccessEnabled;
  const isEmailUsedForLogInPurposesWarningVisible = isPersonnelUserPresent && currentPersonnel.email !== personnel.attributes.email;

  const isProfileAccessToggleVisible = !isSubcontractor && isEditableProfileAccess;

  let profileAccessToggleDisabledReason;
  if (isProfileAccessToggleVisible) {
    if (isAppUserPresent) {
      profileAccessToggleDisabledReason = 'appUserPresent';
    } else if (!currentPersonnel.profileAccessEnabled && currentPersonnel.email.trim().length === 0) {
      profileAccessToggleDisabledReason = 'emailNotPresent';
    }
  }

  useEffect(() => {
    if (sidePanelContext === 'new') { setCurrentPersonnel({ ...currentPersonnel, lineManagerId: '', companyRoleIds: [] }) }
  }, [currentPersonnel.company.companyId])

  useEffect(() => {
    if (sidePanelContext === 'edit' && currentPersonnel.company.subcontractorId) {
      setIsSubcontractor(true)
    }
  }, [])

  const selectDivision = (
    <>
      <SelectDivision
        label={'Division'}
        sidePanelContext={sidePanelContext}
        companies={companies}
        assignedDivision={assignedDivision}
        collectionSelectProps={{ customMargin: 'tw-mb-0' }}
        onChange={onPersonnelOptionChange}
      />
      {isDivisionTeamWarningVisible && (
        <div className='m-l-30 tw-mt-4 tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 tw-flex'>
          <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
            <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
          </div>
          Personnel will be removed from existing teams when moving them to a new division.
        </div>
      )}
    </>
  )

  const selectSubcontractor = (
    <>
      {isSubcontractorProfileWarningVisible && (
        <div className={'m-l-30 tw-mb-4 tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 tw-flex'}>
          <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
            <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
          </div>
          Sub-contractors can’t view profiles
        </div>
      )}
      <SelectSubcontractor
        label={'Subcontractor company'}
        sidePanelContext={sidePanelContext}
        subcontractors={subcontractors}
        primaryDivision={primaryDivision}
        assignedSubcontractor={assignedSubcontractor}
        subcontractor={currentSubcontractor}
        onSubcontractorSelect={onSubcontractorSelect}
        isSubcontractor={isSubcontractor}
        fieldError={requestError.validationErrors.name}
        removeErrorStyling={removeErrorStyling}
        onChange={onPersonnelOptionChange}
      />
    </>
  )

  const formattedValidationErrors = requestError.validationErrors.email ? (
    produce(requestError.validationErrors, draftValidationErrors => {
      draftValidationErrors.email.error.detail = `${draftValidationErrors.email.error.detail}. Please use a different email or contact support.`
    })
  ) : requestError.validationErrors;

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <div className='form-container'>
        <div className='form-container-section'>
          <ErrorMessage wrapperClassName='form-container' validationErrors={formattedValidationErrors} isFallback={requestError.isFallback} withSeparateNestedErrors={false} />
        </div>
        <div className='form-container-section'>
          <TextField label='First name' name='firstName' inputRef={firstEmptyField === 'firstName' ? sidePanelFieldRef : null} value={currentPersonnel.firstName} isRequired={true} fieldError={requestError.validationErrors.firstName} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange}/>
          <TextField label='Last name' name='lastName' inputRef={firstEmptyField === 'lastName' ? sidePanelFieldRef : null} value={currentPersonnel.lastName} isRequired={true} fieldError={requestError.validationErrors.lastName} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange}/>
          <TextField label='Personnel ID' name='externalId' inputRef={firstEmptyField === 'externalId' ? sidePanelFieldRef : null} value={currentPersonnel.externalId} fieldError={requestError.validationErrors.externalId} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange} />
          <div className='tooltip-parent'>
            <TextField label='Email address' name='email' inputRef={firstEmptyField === 'email' ? sidePanelFieldRef : null} value={currentPersonnel.email} fieldError={requestError.validationErrors.email} removeErrorStyling={removeErrorStyling} isDisabled={isAppUserPresent} onChange={(event) => { if (!isAppUserPresent) onPersonnelInputChange(event) }} />
            {isAppUserPresent && <Tooltip className='tooltip-dark--max-w-none tw-top-6' placement='top' trigger='hover' tooltip={<p className='tw-m-0'>As this personnel is also a user, their email address<br/>can only be edited from the user settings</p>} />}
          </div>
          {isEmailUsedForLogInPurposesWarningVisible && (
            <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 -tw-mt-3'>
              <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
                <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
              </div>
              Please note this email is used for log in purposes.
            </div>
          )}
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-my-8 tw-border-0' />
        <div className='form-container-section'>
          <label className='collection-select__label tw-font-medium m-t-32 m-b-24'>Personnel Type</label>
          <div className={'side-panel__radio-buttons-container'}>
            <RadioField label={'Employee'} checked={!isSubcontractor} onChange={() => { setIsSubcontractor(false) }} value='employee' name={'personnelType'} />
            {!isSubcontractor && companies.length > 1 && selectDivision}
            {isProfileAccessToggleVisible && (
              <div data-element-name='profile-access-toggle' className='tw-flex tw-justify-between tw-cursor-pointer m-l-30 tw-mt-4' onClick={() => { if (!profileAccessToggleDisabledReason) onToggle('profileAccessEnabled') }}>
                <div className='tw-flex'>
                  <span className='tw-mr-2'>Allow employee to view their profile</span>
                  <Tooltip placement='top' trigger='hover' tooltip='Employee won’t be able to edit their profile'>
                    <CircleQuestion />
                  </Tooltip>
                </div>
                <div className={classNames('tooltip-parent', profileAccessToggleDisabledReason && 'tw-cursor-not-allowed tw-opacity-30')}>
                  <Switch className='app-switch--md fl-r' onLabel='on' offLabel='off' checked={isAppUserPresent || currentPersonnel.profileAccessEnabled} disabled={!!profileAccessToggleDisabledReason} />
                  {profileAccessToggleDisabledReason && <Tooltip placement='top' trigger='hover' tooltip={profileAccessToggleDisabledTooltip[profileAccessToggleDisabledReason]} />}
                </div>
              </div>
            )}
            <RadioField label={'Sub-contractor'} checked={isSubcontractor} onChange={() => { setIsSubcontractor(true) }} value='subcontractor' name={'personnelType'} />
            {isSubcontractor && selectSubcontractor}
          </div>
          <SelectLineManager isDisabled={isSubcontractor} currentPersonnel={currentPersonnel} lineManagers={lineManagers} onChange={onPersonnelOptionChange} />
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-mt-8 tw-border-0' />
        <div className='form-container-section'>
          <label className='collection-select__label tw-font-medium m-t-32'>Roles</label>
          <SelectCompanyRoles assignableRolesCollection={assignableRoles} onAssignableRoleAdd={onAssignableRoleAdd} primaryDivisionId={primaryDivision.id} onNewCompanyRoleAdd={onNewCompanyRoleAdd} currentPersonnel={currentPersonnel} isCreatable={isCreatableCompanyRole} onChange={onCompanyRolesOptionChange} fieldError={requestError.validationErrors.base} />
          <UserCompanyRolesList
            currentPersonnel={currentPersonnel}
            assignableRolesCollection={assignableRoles}
            onSelectPrimary={onSelectPrimary}
            onRemoveRole={onRemoveRole}
          />
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-mt-8 tw-border-0' />
        <div className='form-container-section'>
          <PersonnelCustomFieldsForm
            availableFields={availableFields}
            availableFieldOptions={availableFieldOptions}
            currentPersonnel={currentPersonnel}
            onPersonnelFieldChange={onPersonnelFieldChange}
            onFieldOptionChange={onFieldOptionChange}
            onDateFieldChange={onDateFieldChange}
            requestError={requestError}
            removeErrorStyling={removeErrorStyling}
          />
        </div>
      </div>
    </div>
  )
}

PersonnelForm.propTypes = {
  assignableCompanies: PropTypes.array,
  currentPersonnel: PropTypes.object,
  user: resourceShape('user'),
  sidePanelContext: PropTypes.string,
  isSubcontractor: PropTypes.bool,
  setIsSubcontractor: PropTypes.func,
  requestError: PropTypes.object,
  removeErrorStyling: PropTypes.func,
  currentSubcontractor: PropTypes.object,
  onSubcontractorSelect: PropTypes.func,
  onPersonnelOptionChange: PropTypes.func,
  onPersonnelInputChange: PropTypes.func,
  isCreatableCompanyRole: PropTypes.bool,
  isEditableProfileAccess: PropTypes.bool,
  lineManagers: PropTypes.array,
  sidePanelBodyRef: PropTypes.any,
  setCurrentPersonnel: PropTypes.func,
  onNewCompanyRoleAdd: PropTypes.func,
  assignableRoles: PropTypes.array,
  onAssignableRoleAdd: PropTypes.func,
  availableFields: PropTypes.object,
  availableFieldOptions: PropTypes.object,
  onPersonnelFieldChange: PropTypes.func,
  onFieldOptionChange: PropTypes.func,
  onDateFieldChange: PropTypes.func,
  onToggle: PropTypes.func
};
