import React, { useState } from "react";
import PropTypes from "prop-types";

import HomeScreen from "components/mobileTrainingProfile/components/HomeScreen";
import PersonnelDetailsScreen from "components/mobileTrainingProfile/components/PersonnelDetailsScreen";
import CourseDetailsScreen from "components/mobileTrainingProfile/components/CourseDetailsScreen";

export default function MobileTrainingProfileApp({
  personnelId,
  appLogoImgSrc,
  isProcoreSession
}) {
  const [screen, setScreen] = useState("home");
  const [currentCourse, setCurrentCourse] = useState({ id: null, isRequired: false });

  const handleViewPersonnelDetailsClick = () => {
    setScreen("personnelDetails")
  }

  const handleViewCourseDetailsClick = ({ id, isRequired }) => {
    setCurrentCourse({ id, isRequired })
    setScreen("courseDetails")
  }

  const handleViewHomeClick = () => {
    setScreen("home")
  }

  return (
    <div className="tw-relative tw-max-w-3xl tw-min-h-screen tw-m-auto tw-pb-25">
      {screen === "home" && (
        <HomeScreen
          personnelId={personnelId}
          appLogoImgSrc={appLogoImgSrc}
          isProcoreSession={isProcoreSession}
          onViewPersonnelDetailsClick={handleViewPersonnelDetailsClick}
          onViewCourseDetailsClick={handleViewCourseDetailsClick}
        />
      )}
      {screen === "personnelDetails" && (
        <PersonnelDetailsScreen
          personnelId={personnelId}
          onViewHomeClick={handleViewHomeClick}
        />
      )}
      {screen === "courseDetails" && (
        <CourseDetailsScreen
          personnelId={personnelId}
          courseId={currentCourse.id}
          isRequired={currentCourse.isRequired}
          onViewHomeClick={handleViewHomeClick}
        />
      )}
    </div>
  )
}

MobileTrainingProfileApp.propTypes = {
  personnelId: PropTypes.string.isRequired,
  appLogoImgSrc: PropTypes.string.isRequired,
  isProcoreSession: PropTypes.bool.isRequired
}
