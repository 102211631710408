import React from "react";
import PropTypes from "prop-types";

import ErrorIcon from "-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg";
import LoadingIcon from "-!svg-react-loader?name=LoadingIcon!icons/loading.svg";

import TextButton from "components/application/buttons/TextButton";

export default function DownloadReportModalContent({
  isReportError,
  closeModal
}) {
  return (
    <>
      <div className="tw-flex tw-items-center tw-flex-col tw-p-6">
        <div className="tw-apply-loading-spinner--blue-light">
          {isReportError ? (
            <ErrorIcon width={48} height={48} className='[&_path]:tw-fill-red-600' />
          ) : (
            <LoadingIcon width={48} height={48} />
          )}
        </div>
        <div className="tw-text-center tw-text-l tw-font-semibold tw-mt-2">
          {isReportError ? 'Report not generated' : 'Generating training report'}
        </div>
        <div className="tw-text-center tw-mt-2">
          {isReportError ? 'Please try again or contact support for help' : 'This may take a few minutes. When it is finished, your report will automatically download so you can close this modal while you wait.'}
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-p-4">
        <TextButton size="md" color="blue" onClick={closeModal}>
          Close
        </TextButton>
      </div>
    </>
  )
}

DownloadReportModalContent.propTypes = {
  isReportError: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
}
