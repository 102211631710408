import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { resourceShape } from "components/helpers/serialisableResources";

import PersonalInfo from "components/application/PersonalInfo";

const moreBreakpoint = 3;

export default function PersonnelDetailsTab({
  fieldValues,
  fieldAttributes,
  fieldOptions,
  defaultDetails
}) {
  const [showAllFields, setShowAllFields] = useState(false);

  const formattedFieldValues = Object.values(fieldValues).reduce((acc, value) => {
    acc[value.relationships.fieldAttribute.data.id] = {
      value: value.attributes.valueType == 'date' ? moment.parseZone(value.attributes.value).toDate() : value.attributes.value,
      valueType: value.attributes.valueType,
      fieldOptionId: value.relationships.fieldOption.data && value.relationships.fieldOption.data.id
    };
    return acc
  }, {});

  const customDetails = fieldAttributes.reduce((acc, fieldAttribute) => {
    const text = findCurrentFieldValue(fieldAttribute, formattedFieldValues, fieldOptions);
    if (text) acc.push({ label: fieldAttribute.attributes.name, text: text })
    return acc
  }, []);

  const allDetails = defaultDetails.filter(field => field.text).concat(customDetails);
  const visibleDetails = (allDetails.length < moreBreakpoint || showAllFields) ? allDetails : allDetails.slice(0, moreBreakpoint);

  return (
    <div className="tw-border-solid tw-border-1 tw-rounded-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-py-6">
      {allDetails.length > 0 ? (
        <>
          {visibleDetails.map((dataPoint, index) => (
            <PersonalInfo
              key={index}
              preserveWhitespace={true}
              isCompact={true}
              label={dataPoint.label}
              text={dataPoint.text}
              copyable={dataPoint.label === 'Email'}
              hideIcon={true}
              className="tw-mb-2"
            />
          ))}
          {!showAllFields && allDetails.length > moreBreakpoint && (
            <div className='flex flex--vertically-centered'>
              <button
                className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                onClick={() => setShowAllFields(true)}
              >
                Show all ({allDetails.length - moreBreakpoint} more)
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="tw-text-grey-500">No details added</div>
      )}
    </div>
  )
}

PersonnelDetailsTab.propTypes = {
  fieldValues: PropTypes.arrayOf(resourceShape('fieldValue')).isRequired,
  fieldAttributes: PropTypes.arrayOf(resourceShape('fieldAttribute')).isRequired,
  fieldOptions: PropTypes.arrayOf(resourceShape('fieldOption')).isRequired,
  defaultDetails: PropTypes.array.isRequired
}

function findCurrentFieldValue(fieldAttribute, formattedFieldValues, fieldOptions) {
  const currentValue = formattedFieldValues[fieldAttribute.id];
  if (!currentValue) return

  if (currentValue.fieldOptionId) {
    return fieldOptions.find((option) => option.id === currentValue.fieldOptionId).attributes.value
  } else {
    switch (currentValue.valueType) {
      case "text": return currentValue.value
      case "date": return moment.parseZone(currentValue.value).format("D MMM YYYY")
    }
  }
}
