import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import Table from 'components/application/Table';

export default function PersonnelTable({ isArchived, isActionsColumnVisible, rows }) {
  const currentActor = useCurrentActor();
  const { hasTeamViewableAccess } = useTrainingRegisterResources();

    return (
      <Table
        headers={
          <tr>
            { currentActor.isAllowedFeature('training_register') && <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'>Status</th>}
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Name</th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Role</th>
            {hasTeamViewableAccess && <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Team</th>}
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Company</th>
            {isActionsColumnVisible && (
              <th className={`tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-right ${isArchived ? 'fw-96' : 'fw-72'}`}>Actions</th>
            )}
          </tr>
        }
        rows={rows}
      />
    )
}

PersonnelTable.propTypes = {
  isArchived: PropTypes.bool,
  isActionsColumnVisible: PropTypes.bool,
  rows: PropTypes.array.isRequired
};
