import React from 'react';
import moment from 'moment';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';

import PersonalInfo from 'components/application/PersonalInfo';

export default function BookingSidePanelDetailsTab({ booking, booker }) {
  const currentActor = useCurrentActor();

  const bookedByDate = moment.parseZone(booking?.attributes.createdAt).format('DD MMM YY [at] HH:mm');
  const bookingDate = moment.parseZone(booking?.attributes.date).format('DD MMM YY')

  const bookerText = (() => {
    if (!booker) return null

    return currentActor.user.attributes.accessType === 'personnel' ? (
      <><span>{personDisplayName(booker.attributes)}</span> <span>&#40;</span><a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href={`mailto:${booker.attributes.email}`}>{booker.attributes.email}</a><span>&#41;</span></>
    ) : (
      `${personDisplayName(booker?.attributes)} on ${bookedByDate}`
    )
  })();

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      <PersonalInfo label='Training date' text={bookingDate} />
      <PersonalInfo label='Booking notes' isTruncated={false} text={booking?.attributes.notes} />
      <PersonalInfo label='Booked by' text={bookerText} />
    </div>
  )
}

BookingSidePanelDetailsTab.propTypes = {
  booking: resourceShape('booking'),
  booker: resourceShape('simpleUser')
}
