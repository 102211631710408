import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useCurrentActor } from "components/contexts/CurrentActor";
import { useBreadBoard } from "components/contexts/Toaster";
import useModal from "components/hooks/useModal";
import useOutsideClick from "components/hooks/useOutsideClick";
import { personDisplayName } from "components/helpers/users";
import { usePersonnel } from "components/mobileTrainingProfile/hooks/reactQuery";
import { formatResources, filterIncluded } from "components/mobileTrainingProfile/helpers/requests";

import PersonIcon from "-!svg-react-loader?name=PersonIcon!icons/person-lg.svg";
import SupportIcon from "-!svg-react-loader?name=SupportIcon!icons/icon-support.svg";
import LogOutIcon from "-!svg-react-loader?name=LogOutIcon!icons/icon-log-out.svg";

import Modal from "components/mobileTrainingProfile/components/Modal";
import TrainingRecordsTabs from "components/mobileTrainingProfile/components/homeScreen/TrainingRecordsTabs";
import SupportModalContent from "components/mobileTrainingProfile/components/homeScreen/SupportModalContent";

export default function HomeScreen({
  personnelId,
  appLogoImgSrc,
  isProcoreSession,
  onViewPersonnelDetailsClick,
  onViewCourseDetailsClick
}) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const [isModalOpen, _setIsModallOpen, openModal, closeModal] = useModal(false);

  const {
    data: personnelQuery,
    isLoading: isPersonnelQueryLoading,
    isError: isPersonnelQueryError
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast
  });

  const userMenuRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useOutsideClick(() => {
    setIsDropdownOpen(false)
  }, userMenuRef)

  const handleSupportClick = () => {
    if (currentActor.user.attributes.accessType === "personnel") {
      openModal()
    } else {
      window.open("https://intercom.help/hands-hq", "_blank")
    }
  }

  const handleLogOutClick = () => {
    axios
      .delete(isProcoreSession ? "/users/integration_sessions/destroy" : "/users/sign_out")
      .then(() => window.location.href = "/users/sign_in")
      .catch(breadBoard.addInedibleToast)
  }

  const toggleMenu = () => setIsDropdownOpen(prevState => !prevState)

  if (isPersonnelQueryLoading || isPersonnelQueryError) return null

  const personnel = personnelQuery.personnel.indexedById[personnelId];
  const lineManager = personnelQuery.lineManagers.indexedById[personnel.relationships.lineManager.data?.id];

  return (
    <>
      <div className="tw-flex tw-items-center tw-bg-grey-900 tw-h-20 tw-w-full tw-p-3">
        <div className="tw-flex-none tw-flex tw-items-center tw-border-solid tw-border-0 tw-border-r-1 tw-border-grey-500 tw-w-13 tw-h-full">
          <img className="tw-h-6 tw-ml-1" src={appLogoImgSrc} />
        </div>
        <div className="tw-flex-auto tw-text-grey-025 tw-ml-4 tw-mr-4">
          <h1 className="tw-font-mark-pro tw-text-xl tw-font-bold tw-tracking-tight tw-m-0">{personDisplayName(personnel.attributes)}</h1>
          <h2 className="tw-text-m tw-font-normal tw-tracking-auto tw-m-0">{currentActor.account.attributes.name}</h2>
        </div>
        <div ref={userMenuRef} className="tw-relative">
          <div data-element-name="user-menu-toggle" className={classNames("tw-flex-none tw-flex tw-items-center tw-justify-center tw-border-solid tw-border-1 hover:tw-border-grey-500 tw-rounded-lg tw-cursor-pointer tw-w-10 tw-h-10", isDropdownOpen ? "tw-border-grey-500" : "tw-border-transparent")} onClick={toggleMenu}>
            <div className="tw-flex tw-justify-between tw-w-4 tw-h-1">
              <div className="tw-bg-grey-025 tw-rounded-full tw-w-1 tw-h-1"></div>
              <div className="tw-bg-grey-025 tw-rounded-full tw-w-1 tw-h-1"></div>
              <div className="tw-bg-grey-025 tw-rounded-full tw-w-1 tw-h-1"></div>
            </div>
          </div>
          {isDropdownOpen && (
            <div data-element-name="user-menu-dropdown" className="tw-absolute tw-right-0 tw-top-11 tw-rounded-lg tw-border-1 tw-border-solid tw-bg-white tw-border-grey-100 tw-shadow-lg tw-tw-w-60 tw-z-[9999]">
              <div className="tw-flex tw-flex-col tw-w-54" onClick={toggleMenu}>
                <div className="tw-flex tw-items-center tw-cursor-pointer tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-px-3 tw-h-11" onClick={onViewPersonnelDetailsClick}>
                  <PersonIcon className="[&_polygon]:tw-fill-grey-900 tw-ml-0.5 tw-mr-2" width={16} height={16} />
                  View personnel details
                </div>
                <div className="tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-px-3 tw-h-11" onClick={handleSupportClick}>
                  <SupportIcon className="[&_polygon]:tw-fill-grey-900 tw-mr-2" width={20} height={20} />
                  Support
                </div>
                <div className="tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-px-3 tw-h-11" onClick={handleLogOutClick}>
                  <LogOutIcon className="[&_polygon]:tw-fill-grey-900 tw-mr-2" width={20} height={20} />
                  Log out
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="tw-p-3">
        <TrainingRecordsTabs
          personnelId={personnelId}
          onViewCourseDetailsClick={onViewCourseDetailsClick}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <SupportModalContent
          lineManager={lineManager}
          closeModal={closeModal}
        />
      </Modal>
    </>
  )
}

HomeScreen.propTypes = {
  personnelId: PropTypes.string.isRequired,
  appLogoImgSrc: PropTypes.string.isRequired,
  isProcoreSession: PropTypes.bool.isRequired,
  onViewPersonnelDetailsClick: PropTypes.func.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired
}

const personnelQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    personnel: formatResources({ resources: [data], options: { indexedById: { path: "id" } } }),
    lineManagers: formatResources({ resources: filterIncluded({ included, type: "lineManager" }), options: { indexedById: { path: "id" } } })
  }
}
