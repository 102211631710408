import React, { useState } from 'react';

import Tooltip from 'components/application/Tooltip';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import FormFooter from 'components/sidepanels/FormFooter';
import TrainingFormFields from 'components/training/TrainingFormFields';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';

import RadioField from 'components/application/RadioField';

export default function TrainingRequirementsSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const trackedPersonnelContext = useTrackedPersonnelContext();

  const courseAttributes = (props.currentTraining.course && props.currentTraining.course.attributes) || {};

  const header = (
    <div className='popup__title popup__title--tertiary-split'>
      <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>{`${props.sidePanelContext === 'new' ? 'Add' : 'Edit'} training course for`}</h1>
      <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{courseAttributes.name}</h2>
    </div>
  );

  const body = (
    <div className='popup__body-form'>
      <ErrorMessage wrapperClassName='form-container' validationErrors={props.requestError.validationErrors} isFallback={props.requestError.isFallback} />
      <div className='form-container'>
        <label className='field__label tw-font-medium'>
          <span className='m-r-4'>Is this course required?</span>
        </label>
        <div className='tw-mb-4'>
          <RadioField
              checked={props.currentTrainingRequirement.isCourseRequired}
              onChange={() => props.onTrainingRequirementInputChange({ target: { name: 'isCourseRequired', value: true } })}
              value={true}
              name='required_true'
              label={'Required'}
            />
          <div className='tw-text-s tw-font-normal tw-px-7 tw-mt-[-8px]'>Affects training status, email notifications will be sent</div>
        </div>
        <div>
          <RadioField
            checked={!props.currentTrainingRequirement.isCourseRequired}
            onChange={() => props.onTrainingRequirementInputChange({ target: { name: 'isCourseRequired', value: false } })}
            value={false}
            name='required_false'
            label={'Optional'}
          />
          <div className='tw-text-s tw-font-normal tw-px-7 tw-mt-[-8px]'>Training status unaffected, no email notifications sent</div>
        </div>
        <hr className='m-t-32 m-r--20 m-b-32 m-l--20' />
        <label className='field__label tw-font-medium'>Has this training already taken place?</label>
        <RadioField
          checked={!props.currentTrainingRequirement.isRecordingTraining}
          onChange={() => props.onTrainingRequirementInputChange({ target: { name: 'isRecordingTraining', value: false } })}
          value={false}
          name='record_training_false'
          label={'No'}
        />
        <ConditionalWrapper
          condition={trackedPersonnelContext.hasReachedLimit && !trackedPersonnelContext.isCurrentPersonnelTracked}
          wrapper={children => <Tooltip placement='left' trigger='hover' tooltip="You have reached your training limit and can’t record training for this personnel" className='tooltip-dark--max-w-xxs'>{children}</Tooltip>}>
          <RadioField
            disabled={trackedPersonnelContext.hasReachedLimit && !trackedPersonnelContext.isCurrentPersonnelTracked}
            checked={props.currentTrainingRequirement.isRecordingTraining}
            onChange={() => props.onTrainingRequirementInputChange({ target: { name: 'isRecordingTraining', value: true } })}
            value={true}
            name='record_training_true'
            label={'Yes'}
          />
        </ConditionalWrapper>
        {props.currentTrainingRequirement.isRecordingTraining && (
          <TrainingFormFields
            currentTraining={props.currentTraining}
            requestError={props.requestError}
            removeErrorStyling={props.removeErrorStyling}
            onInputChange={props.onTrainingInputChange}
            onError={props.onError}
            includedAttachments={props.includedAttachments}
            includedCoverImages={props.includedCoverImages}
            sidePanelIsOpen={props.sidePanelIsOpen}
            onTrainingDateChange={props.onTrainingDateChange}
            onTrainingCalendarClose={props.onTrainingCalendarClose}
          />
        )}
      </div>
    </div>
  );

  const handleCancel = () => {
    props.closeSidePanel()
    props.resetRequestError()
  }

  const footer = (
    <FormFooter
      text={props.sidePanelContext === 'new' ? 'Add training course' : 'Save changes'}
      onCancel={handleCancel}
      onSubmit={props.sidePanelContext === 'new' ? props.createTrainingRequirement : props.updateTraining}
      submitButtonDisabled={props.submitDisabled}
    />
  );

  const onBackdropClick = () => !(['new', 'edit'].includes(props.sidePanelContext)) && props.closeSidePanel();

  return (
    <SidePanel
      color={props.sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={props.sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={false}
      onBackdropClick={onBackdropClick}
      closeCallback={props.closeSidePanel}
      headerContent={header}
      bodyContent={body}
      footerContent={footer}
      contentContext={props.sidePanelContext}
      submitDisabled={props.submitDisabled}
    />
  )
}
