import React from 'react';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import AddButton from 'components/buttons/AddButton';
import SortableCollection from 'components/collections/SortableCollection';
import ErrorMessage from 'components/application/ErrorMessage';

export default function CustomPersonSidePanelBody(props) {
  const {
    collection,
    displayValidationErrors,
    isFallback,
    onAdd,
    resetDefaults
  } = props;

  return (
    <React.Fragment>
      <div className="tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-20">
        <div className='m-r-12 fh-20'>
          <InfoIcon width={20} height={20} className="[&_path]:tw-fill-cyan-800" />
        </div>
        <p className='m-0'>Changes will not update existing risk assessments</p>
      </div>
      <ErrorMessage
        wrapperClassName='form-container'
        modifiers='side-panel__alert side-panel__alert--danger'
        validationErrors={displayValidationErrors}
        isFallback={isFallback}
      />
      <SortableCollection collection={collection} collectionProps={props}/>
      <div className='side-panel__options-container'>
        <AddButton
          text='Add another'
          onClick={ () => {onAdd()} }
        />
        <button className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-font-inter tw-text-m tw-font-medium tw-tracking-auto reset-defaults-button' onClick={resetDefaults}>
          Reset to default list
        </button>
      </div>
    </React.Fragment>
  )
}
