import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserManagementTable from 'components/users/UserManagementTable';
import AllowanceBar from 'components/users/AllowanceBar';
import Paginator from 'components/application/Paginator';
import useDebounce from 'components/hooks/useDebounce';
import ToastRack from 'components/application/ToastRack';
import Toast from 'components/application/Toast';
import useToasts from 'components/hooks/useToasts';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import UserSidePanel from 'components/users/UserSidePanel';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import useSidePanel from 'components/hooks/useSidePanel';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources'
import { CurrentActorContext } from 'components/contexts/CurrentActor';

const defaultStore = {
  loaded: false,
  users: [],
  divisions: [],
  allowedUsersCount: undefined,
  currentUsersCount: undefined,
  accessControls: [],
  currentPage: 1,
  totalPages: 1
}

export default function IndexPage({ currentDivision, currentUser, divisions, isSamlSsoAllowed, isSamlSsoEnforced, isTrainingRegisterAllowed, identityProvider, toastMessages, initialUser, initialAction, projectDisplayNameSingular, isTeamsAllowed }) {
  const [usersManagementStore, setUsersManagementStore] = useState(defaultStore);
  const [currentSearch, setCurrentSearch] = useState(initialUser && initialUser.attributes.email || '');
  const [debouncedCurrentSearch] = useDebounce(currentSearch, 250);
  const [selectedDivisionId, setSelectedDivisionId] = useState('');
  const [toasts, , addToast, handleBurnToast, ,] = useToasts();
  const [sidePanelIsOpen, , openSidePanel, closeSidePanel, , sidePanelContext, setSidePanelContext] = useSidePanel(!!initialAction, initialAction || 'new');
  const [selectedUserId, setSelectedUserID] = useState(initialUser && initialUser.id || null);

  // computed
  const formattedUsersAsRows = usersManagementStore.users.map((user) => {
    const rowProps = { id: user.id, ...{ ...user.attributes } };
    rowProps.divisions = usersManagementStore.divisions.filter((division) =>
      user.relationships.assignedDivisions.data.map((ad) => ad.id).includes(division.id)
    );
    rowProps.accessControl = usersManagementStore.accessControls.find((ac) =>
      ac.relationships.user.data.id === user.id &&
      ac.relationships.company.data.id === currentDivision.id
    ) || {};
    return rowProps;
  })

  const selectedUser = selectedUserId && usersManagementStore.users.find((u) => u.id === selectedUserId);
  const relevantAccessControl = selectedUser && usersManagementStore.accessControls.find((ac) =>
    ac.relationships.user.data.id === selectedUserId &&
    ac.relationships.company.data.id === currentDivision.id
  );

  let selectedUserAsForm = null;
  if (selectedUser) {
    selectedUserAsForm = { isSelected: true, domainAccessControlID: relevantAccessControl && relevantAccessControl.id };
    selectedUserAsForm = { ...selectedUserAsForm, ...(selectedUser.attributes) };
    if (relevantAccessControl) selectedUserAsForm = { ...selectedUserAsForm, ...(relevantAccessControl.attributes) };
  }
  const isManagingMultiDivisions = divisions.length > 1;

  // handlers
  const handleDivisionSelection = (e) => setSelectedDivisionId(e.value);
  const handleSearchChange = (e) => setCurrentSearch(e.target.value);
  const handleSearchReset = () => setCurrentSearch('');
  const handleResendInvitation = ({ name }) => addToast(<InvitationResentToast name={name} {...{ handleBurnToast }} />)
  const handlePasswordReset = ({ name }) => addToast(<PasswordResetToast name={name} {...{ handleBurnToast }} />)
  const handleAddUserClick = () => {
    setSidePanelContext('new')
    if (!isManagingMultiDivisions) {
      setSelectedUserID(null)
      openSidePanel()
    } else {
      window.location.assign('/users/new')
    }
  }

  const handleEditUserClick = (userID) => {
    if (!isManagingMultiDivisions) {
      setSidePanelContext('edit')
      setSelectedUserID(userID)
      openSidePanel()
    } else {
      window.location.assign(`/users/${userID}/edit`)
    }
  }

  const handleUserRemoved = (removedUserID, wasRevoked = false) => {
    const removedUserName = personDisplayName(usersManagementStore.users.find((u) => u.id === removedUserID).attributes)
    refreshLocalCache()
    addToast(<UserRemovedToast divisionName={currentDivision.attributes.name} wasRevoked={wasRevoked} name={removedUserName} {...{ handleBurnToast }} />)
  }

  const handleUserCreated = (serializedInvitedUser) => {
    refreshLocalCache()
    addToast(
      <UserCreatedToast
        user={serializedInvitedUser}
        isSamlSsoAllowed={isSamlSsoAllowed}
        {...{ handleBurnToast }}
      />
    )
  }

  const handleUserUpdated = (user) => {
    refreshLocalCache()
    addToast(<UserUpdatedToast user={user} {...{ handleBurnToast }} />)
  }

  const handlePageChange = (event) => {
    fetchUsers({ page: event.currentTarget.getAttribute('data-page'), search: debouncedCurrentSearch, divisionId: selectedDivisionId })
  };

  // utilities
  function fetchUsers(fetchOptions = { page: 1, search: null, divisionId: '' }) {
    const divisionScoping = divisions.length === 1 ? divisions[0].id : fetchOptions.divisionId;
    const usersParams = { params: { page: fetchOptions.page, search: fetchOptions.search, division_id: (divisionScoping === '' ? null : divisionScoping) } }

    axios
      .get('/users', usersParams)
      .then((response) => {
        setUsersManagementStore({
          loaded: true,
          users: response.data.data,
          divisions: response.data.included.filter(inclusion => inclusion.type === 'company'),
          accessControls: response.data.included.filter(inclusion => inclusion.type === 'accessControl'),
          allowedUsersCount: response.data.meta.allowedUsersCount,
          currentUsersCount: response.data.meta.currentUsersCount,
          currentPage: response.data.meta.currentPage,
          totalPages: response.data.meta.totalPages
        })
      })
  }

  // going with 'wasteful' approach to solve sync issues, can make smarter if need arises
  function refreshLocalCache() {
    setSelectedUserID(null)
    closeSidePanel()
    fetchUsers({ page: usersManagementStore.currentPage, search: debouncedCurrentSearch, divisionId: selectedDivisionId })
  }

  // side effects
  useEffect(() => {
    fetchUsers({ search: debouncedCurrentSearch, divisionId: selectedDivisionId })
  }, [debouncedCurrentSearch, selectedDivisionId])

  useEffect(() => {
    if (toastMessages) {
      // only supporting 1
      const toast = toastMessages[0];
      const ToastComponent = {
        userCreated: UserCreatedToast,
        userUpdated: UserUpdatedToast
      }[toast.type];

      addToast(<ToastComponent isSamlSsoAllowed={isSamlSsoAllowed} {...toast.data} {...{ handleBurnToast }} />)
    }
  }, [])

  const isAtUserCapacity = usersManagementStore.allowedUsersCount !== 0 && usersManagementStore.currentUsersCount >= usersManagementStore.allowedUsersCount;

  return (
    usersManagementStore.loaded && <CurrentActorContext.Provider
      value={{ division: currentDivision, user: currentUser, isManagingMultiDivisions, isSamlSsoAllowed, isSamlSsoEnforced, isTrainingRegisterAllowed, identityProvider }}
    >
      <AllowanceBar
        currentCount={usersManagementStore.currentUsersCount}
        allowance={usersManagementStore.allowedUsersCount}
        asRemaining={!isManagingMultiDivisions}
      />
      <UserManagementTable
        users={formattedUsersAsRows}
        divisionSelectionOptions={{ isSelectable: isManagingMultiDivisions, onDivisionSelection: handleDivisionSelection, ...{ divisions, selectedDivisionId } }}
        searchOptions={{ value: currentSearch, debouncedValue: debouncedCurrentSearch, onChange: handleSearchChange, onReset: handleSearchReset }}
        isAtUserCapacity={isAtUserCapacity}
        onAddUserClick={handleAddUserClick}
        onResendInvitation={handleResendInvitation}
        onPasswordReset={handlePasswordReset}
        onRemoveUser={handleUserRemoved}
        onEditClick={handleEditUserClick}
      />
      {
        usersManagementStore.loaded && usersManagementStore.users.length > 0 && usersManagementStore.totalPages > 1 && (
          <div className='m-t-80 text-center'>
            <Paginator
              currentPage={usersManagementStore.currentPage}
              totalPages={usersManagementStore.totalPages}
              onClick={handlePageChange}
            />
          </div>
        )
      }
      <ToastRack toasts={toasts} />
      {!isManagingMultiDivisions &&
        <UserSidePanel
          selectedUserId={selectedUserId}
          currentUser={currentUser}
          isPermissionsEditable={(selectedUser && selectedUser.attributes.accessType === 'regular' || !selectedUser)}
          context={sidePanelContext}
          isOpen={sidePanelIsOpen}
          onClose={closeSidePanel}
          divisionName={divisions[0].attributes.name}
          divisionId={divisions[0].id}
          assignablePermissions={currentDivision.attributes.assignablePermissions}
          isCourseApplicableResource={currentDivision.attributes.isCourseApplicableResource}
          onCreate={handleUserCreated}
          onUpdate={handleUserUpdated}
          defaultUser={selectedUserAsForm}
          projectDisplayNameSingular={projectDisplayNameSingular}
          isTeamsAllowed={isTeamsAllowed}
        />
      }
    </CurrentActorContext.Provider>
  )
}

const InvitationResentToast = ({ name, handleBurnToast }) => (
  <Toast
    toastKey={`invitationResentToast--${name}`}
    burn={handleBurnToast}
    modifiers='no-undo'
    burnTimer={5000}
    contents={
      <>
        <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
        <div className='toast__text'>An invitation has been resent to {name}</div>
      </>
    }
  />
)

export const UserCreatedToast = ({ user, handleBurnToast, isSamlSsoAllowed }) => {
  const name = user.data.attributes.firstName

  const personnel = user.meta.personnelGenerated && user.included.find((inclusion) => inclusion.type === 'personnel');
  const personnelProfilePath = personnel && personnel.links.profile;

  const companyName = personnel && user.included.find(inclusion => inclusion.type === 'company' && inclusion.id === personnel.relationships.company.data.id).attributes.name;
  const wasGenerated = !!(personnel);

  return <Toast
    toastKey={`userCreatedToast--${name}`}
    burn={handleBurnToast}
    modifiers='no-undo'
    burnTimer={10000}
    contents={
      <>
        <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
        <div className='toast__text'>
          {name.length > 10 ? `${name.slice(0, 10)}...` : name} was {isSamlSsoAllowed ? 'added' : 'invited'} as a user.{wasGenerated ? ` A personnel profile was also created for them in ${companyName.length > 20 ? `${companyName.slice(0, 20)}...` : `${companyName}.`}` : ''}
          {wasGenerated && <div><OutlinedButton className='m-t-12' size='sm' color='grey' href={personnelProfilePath}>View personnel profile</OutlinedButton></div>}
        </div>
      </>
    }
  />
}

const PasswordResetToast = ({ name, handleBurnToast }) => {
  return (
    <Toast
      toastKey={`passwordResetToast--${name}`}
      burn={handleBurnToast}
      modifiers='no-undo'
      burnTimer={5000}
      contents={
        <>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>Password successfully reset</div>
        </>
      }
    />
  )
}

const UserUpdatedToast = ({ user, handleBurnToast }) => {
  const name = personDisplayName(user.data.attributes);
  return (
    <Toast
      toastKey={`userUpdatedToast--${name}`}
      burn={handleBurnToast}
      modifiers='no-undo'
      burnTimer={5000}
      contents={
        <>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>{`${name} was updated`}</div>
        </>
      }
    />
  )
}

const UserRemovedToast = ({ name, handleBurnToast, divisionName, wasRevoked }) => (
  <Toast
    toastKey={`userRemovedToast--${name}`}
    burn={handleBurnToast}
    modifiers='no-undo'
    burnTimer={5000}
    contents={
      <>
        <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
        <div className='toast__text'>{`${name} was removed${wasRevoked ? ` from ${divisionName}` : ''} `}</div>
      </>
    }
  />
)

IndexPage.propTypes = {
  currentDivision: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  divisions: PropTypes.array.isRequired,
  isSamlSsoAllowed: PropTypes.bool.isRequired,
  isSamlSsoEnforced: PropTypes.bool.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired,
  isTrainingRegisterAllowed: PropTypes.bool.isRequired,
  initialUser: resourceShape('user'),
  initialAction: PropTypes.oneOf(['edit'])
}
