export default function useSwipe({ onSwipeLeft, onSwipeRight}) {
  let touchStartX, touchEndX;

  function checkSwipeDirection() {
    if (touchEndX < touchStartX) onSwipeLeft()
    if (touchEndX > touchStartX) onSwipeRight()
  }

  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].screenX;
  }

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].screenX;
    checkSwipeDirection()
  }

  return {
    handleTouchStart,
    handleTouchEnd
  }
}
