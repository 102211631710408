var React = require('react');

function GreenTickIcon (props) {
    return React.createElement("svg",props,React.createElement("g",{"fill":"none","fillRule":"evenodd"},[React.createElement("rect",{"width":"24","height":"24","fill":"#47B881","rx":"12","key":0}),React.createElement("polygon",{"fill":"#FFF","points":"7.164 11.107 5.75 12.521 10.551 17.322 18.246 7.428 16.668 6.2 10.363 14.306","key":1})]));
}

GreenTickIcon.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = GreenTickIcon;

GreenTickIcon.default = GreenTickIcon;
