import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { groupTrainingByCourseId, courseTrainingStatusOrdering } from 'components/helpers/resources/training';
import { getELearningCourseId } from 'components/helpers/resources/courses';
import { sortAlphabetically, sortByDate } from 'components/helpers/arrays';
import { mappedAutoEnrolmentExclusionsByConfigurationId } from 'components/helpers/resources/autoEnrolments';

import Table from 'components/application/Table';
import CourseTraining from 'components/training/CourseTraining';

export default function RoleTrainingTable(props) {
  const currentActor = useCurrentActor();

  const trainingByCourseId = groupTrainingByCourseId({ training: props.training });

  const rows = props.courses.reduce((acc, course) => {
    const courseCompanyRole = props.courseCompanyRoles[course.id];
    const latestTraining = (trainingByCourseId[course.id] && trainingByCourseId[course.id][0]) || null;
    const userCourse = props.userCourses.find(userCourse => userCourse.relationships.course.data.id == course.id);
    const isRequired = (userCourse && userCourse.attributes.required) || courseCompanyRole.attributes.required;

    const orderingBand = courseTrainingStatusOrdering({ course, isRequired, training: latestTraining });

    acc.push({ course, latestTraining, orderingBand, courseCompanyRole, userCourse })
    return acc
  }, [])

  const orderedRows = rows.sort((a, b) => {
    const sortByOrderingBand = (a, b) => a.orderingBand - b.orderingBand
    const orderingSort = sortByOrderingBand(a, b);
    if (orderingSort !== 0) { return orderingSort }
    const dateSort = sortByDate(a.latestTraining && a.latestTraining.attributes.expiryDate, b.latestTraining && b.latestTraining.attributes.expiryDate);
    if (dateSort !== 0) { return dateSort }
    return sortAlphabetically(a.course.attributes.name, b.course.attributes.name)
  })

  return (
    <Table
      headers={
        <tr>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'></th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Name</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-124'>Expiry date</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-216'>Bookings</th>
          {currentActor.user.attributes.accessType !== 'personnel' && <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-72'>Actions</th>}
        </tr>
      }
      rows={
        orderedRows.map((orderedRow) => {
          const course = orderedRow.course;
          const eLearningCourse = props.eLearningCourses[getELearningCourseId(course)];
          const autoEnrolmentConfiguration = props.autoEnrolmentConfigurations && props.autoEnrolmentConfigurations[course.relationships?.autoEnrolmentConfiguration?.data?.id]
          const autoEnrolmentExclusion = props.autoEnrolmentExclusions && mappedAutoEnrolmentExclusionsByConfigurationId(props.autoEnrolmentExclusions)[autoEnrolmentConfiguration?.id]
          const courseCompanyRole = orderedRow.courseCompanyRole;
          const booking = props.currentBookings.find(booking => booking.relationships.course.data.id == course.id);
          const booker = props.bookers && props.bookers[booking?.relationships?.booker?.data?.id]
          const registration = props.currentRegistrations.find(registration => registration.relationships.course.data.id == course.id);
          const userCourse = orderedRow.userCourse;
          const isRequiredByUserCourse = userCourse && userCourse.attributes.required;
          const isRequiredByCourseCompanyRole = courseCompanyRole.attributes.required;
          let requiredSource;
          if (isRequiredByUserCourse) {
            requiredSource = 'UserCourse';
          }
          else if (isRequiredByCourseCompanyRole) {
            requiredSource = 'Role';
          }

          return (
            <CourseTraining
              key={`roleCourseTraining--${course.id}`}
              hasTrainingEditableAccess={props.hasTrainingEditableAccess}
              eLearningAllowed={props.eLearningAllowed}
              isRequired={isRequiredByUserCourse || isRequiredByCourseCompanyRole}
              requiredSource={requiredSource}
              course={course}
              eLearningCourse={eLearningCourse}
              eLearningAllowance={props.eLearningAllowance}
              autoEnrolmentConfiguration={autoEnrolmentConfiguration}
              autoEnrolmentExclusion={autoEnrolmentExclusion}
              training={trainingByCourseId[course.id]}
              booking={booking}
              booker={booker}
              registration={registration}
              attachments={props.attachments}
              currentPersonnel={props.currentPersonnel}
              creditInfoUnavailable={props.creditInfoUnavailable}
              onNewTrainingClick={props.onNewTrainingClick}
              onEditTrainingClick={props.onEditTrainingClick}
              onAddBooking={props.onAddBooking}
              onAddELearningBooking={props.onAddELearningBooking}
              onRemoveBooking={props.onRemoveBooking}
              onBookingReminder={props.onBookingReminder}
              onDeleteTrainingClick={props.onDeleteTrainingClick}
              setModalBoxData={props.setModalBoxData}
              personnelId={props.personnelId}
              onShowBooking={props.onShowBooking}
              onCreateAutoEnrolmentExclusion={props.onCreateAutoEnrolmentExclusion}
              onDeleteAutoEnrolmentExclusion={props.onDeleteAutoEnrolmentExclusion}
            />
          )
        })
      }
    />
  )
}

RoleTrainingTable.propTypes = {
  courses: PropTypes.array.isRequired,
  eLearningCourses: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  autoEnrolmentExclusions: PropTypes.object,
  training: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  hasTrainingEditableAccess: PropTypes.bool,
  currentPersonnel: PropTypes.object,
  currentBookings: PropTypes.array,
  currentRegistrations: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminder: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func
}
