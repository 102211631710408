import React, { useState, useEffect, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import { personnelParams } from 'components/helpers/personnel';
import FormFooter from 'components/sidepanels/FormFooter';
import SidePanel from 'components/application/SidePanel';
import PersonnelForm from 'components/personnel/PersonnelForm';
import usePersonnelForm from 'components/hooks/usePersonnelForm';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sortRoleIdsByPosition } from 'components/helpers/personnel';
import { ProjectPersonnelContext } from 'components/contexts/ProjectPersonnelContext';

export default function ProjectPersonnelSidePanel(props) {
  const currentActor = useCurrentActor();
  const projectPersonnelContext = React.useContext(ProjectPersonnelContext);
  const sidePanelFieldRef = useRef();
  const defaultPersonnel = {
    id: '',
    firstName: '',
    externalId: '',
    lastName: '',
    email: '',
    lineManagerId: '',
    company: {
      companyId: currentActor.division.id,
      subcontractorId: ''
    },
    primaryCompanyRoleId: '',
    companyRoleIds: [],
    fieldValues: {},
    profileAccessEnabled: false
  }


  const defaultSubcontractor = { subcontractorName: '' };

  const [scrolledAmount, setScrolledAmount] = useState(0);
  const [currentSubcontractor, onSubcontractorSelect] = useForm(defaultSubcontractor);
  const [isSubcontractor, setIsSubcontractor] = useState(false)
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [currentPersonnel, setCurrentPersonnel, handlePersonnelInputChange, handlePersonnelOptionChange, handlePersonnelDateChange, handleFieldValueInputChange, handleDateFieldChange, handleFieldOptionChange, handleToggle] = usePersonnelForm(defaultPersonnel)
  const [assignableCompanies, setAssignableCompanies] = useState({ loaded: false, collection: [] });
  const [assignableRoles, setAssignableRoles] = useState({ loaded: false, collection: [] });
  const [newCompanyRoles, setNewCompanyRoles] = useForm([])
  const [sidePanelIsOpen, setSidePanelIsOpen] = useState(false);

  const openSidePanel = () => { setSidePanelIsOpen(true) };
  const closeSidePanel = () => { setSidePanelIsOpen(false) };

  const resetAllFields = () => {
    setCurrentPersonnel(defaultPersonnel)
    onSubcontractorSelect(defaultSubcontractor)
    setIsSubcontractor(false)
    setNewCompanyRoles([])
    props.resetCreateOptionInputValue()
  }

  const fetchResources = () => {
    fetchAssignableCompanies()
    fetchAssignableCompanyRoles()
  }

  const setDefaultPersonnel = () => {
    const lastIndex = props.createOptionInputValue.lastIndexOf(' ');
    let firstName = props.createOptionInputValue, lastName = '';

    if (lastIndex !== -1) {
      firstName = props.createOptionInputValue.substr(0, lastIndex);
      lastName = props.createOptionInputValue.substr(lastIndex + 1);
    }

    setCurrentPersonnel({ ...defaultPersonnel, firstName: firstName, lastName: lastName })
  };

  const fetchAssignableCompanies = function () {
    axios
      .get('/assignable_companies')
      .then(response => setAssignableCompanies({ loaded: true, collection: response.data.data }))
  };

  const fetchAssignableCompanyRoles = function () {
    axios
      .get('/assignable_company_roles')
      .then(response => setAssignableRoles({ loaded: true, collection: response.data.data }))
  }

  const handleNewCompanyRoleAdd = (newRole) => {
    setNewCompanyRoles(newCompanyRoles.concat([newRole]))
  }

  const onAssignableRoleAdd = (newRole) => {
    setAssignableRoles({ collection: [...assignableRoles.collection, newRole], loaded: true })
  }

  const createPersonnel = () => {
    axios
      .post('/personnel', personnelParams({ currentPersonnel: currentPersonnel, currentSubcontractor: currentSubcontractor, isSubcontractor: isSubcontractor, newCompanyRoles: newCompanyRoles }))
      .then((response) => {
        resetAllFields()
        fetchResources()
        resetRequestError()
        closeSidePanel()
        props.createProjectPersonnel({ value: response.data.data.id }, {}, { userInvited: response.data.meta.userInvited })
      })
      .catch(handleRequestError)
  };

  useEffect(() => {
    fetchAssignableCompanies()
    fetchAssignableCompanyRoles()
  }, [])

  useEffect(() => {
    if (props.createOptionInputValue) {
      setDefaultPersonnel()
      resetRequestError()
      openSidePanel()
    }
  }, [props.createOptionInputValue])

  const handleCancel = () => {
    resetAllFields()
    closeSidePanel()
  }

  const handleCompanyRolesOptionChange = (values) => {
    if (currentPersonnel.primaryCompanyRoleId === "" && currentPersonnel.companyRoleIds.length === 0) {
      setCurrentPersonnel({ ...currentPersonnel, primaryCompanyRoleId: values[0].value, companyRoleIds: values.map(v => v.value) })
    } else {
      setCurrentPersonnel({ ...currentPersonnel, companyRoleIds: values.map(v => v.value) })
    }
  }

  function handleSelectPrimary(id) {
    setCurrentPersonnel({ ...currentPersonnel, primaryCompanyRoleId: id })
  }

  function handleRemoveRole(id) {
    const filteredCompanyRoleIds = currentPersonnel.companyRoleIds.filter(roleId => roleId !== id);
    const newPrimaryCompanyRoleId = filteredCompanyRoleIds.length > 0 ? sortRoleIdsByPosition(assignableRoles.collection, filteredCompanyRoleIds)[0] : "";
    setCurrentPersonnel({
      ...currentPersonnel,
      companyRoleIds: filteredCompanyRoleIds,
      primaryCompanyRoleId: currentPersonnel.primaryCompanyRoleId === id ? newPrimaryCompanyRoleId : currentPersonnel.primaryCompanyRoleId
    })
  }

  return assignableCompanies.loaded && (
    <SidePanel
      color='tertiary'
      isOpen={sidePanelIsOpen}
      onOpen={() => focusField(sidePanelFieldRef)}
      displayClose={false}
      closeCallback={handleCancel}
      headerContent={(
        <div className='popup__title popup__title--tertiary'><h1 className='tw-text-xl tw-font-semibold tw-tracking-tight'>Add new personnel</h1></div>
      )}
      bodyContent={(
        <PersonnelForm
          sidePanelContext='new'
          currentPersonnel={currentPersonnel}
          setCurrentPersonnel={setCurrentPersonnel}
          requestError={requestError}
          lineManagers={[]}
          assignableCompanies={assignableCompanies.collection}
          assignableRoles={assignableRoles.collection}
          onAssignableRoleAdd={onAssignableRoleAdd}
          currentSubcontractor={currentSubcontractor}
          onSubcontractorSelect={onSubcontractorSelect}
          isSubcontractor={isSubcontractor}
          setIsSubcontractor={setIsSubcontractor}
          isCreatableCompanyRole={projectPersonnelContext.userHasRoleCreatableAccess}
          isEditableProfileAccess={projectPersonnelContext.userHasProfileAccessEditableAccess}
          onPersonnelInputChange={handlePersonnelInputChange}
          onPersonnelOptionChange={handlePersonnelOptionChange}
          onPersonnelDateChange={handlePersonnelDateChange}
          onNewCompanyRoleAdd={handleNewCompanyRoleAdd}
          removeErrorStyling={removeErrorStyling}
          submitDisabled={submitDisabled}
          availableFields={props.availableFields}
          availableFieldOptions={props.availableFieldOptions}
          onPersonnelFieldChange={handleFieldValueInputChange}
          onFieldOptionChange={handleFieldOptionChange}
          onDateFieldChange={handleDateFieldChange}
          onToggle={handleToggle}
          onCompanyRolesOptionChange={handleCompanyRolesOptionChange}
          onSelectPrimary={handleSelectPrimary}
          onRemoveRole={handleRemoveRole}
          sidePanelFieldRef={sidePanelFieldRef}
          sidePanelIsOpen={sidePanelIsOpen}
        />
      )}
      footerContent={(
        <FormFooter
          text='Add personnel'
          onCancel={handleCancel}
          onSubmit={createPersonnel}
          submitButtonDisabled={submitDisabled}
        />
      )}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      contentContext={'new'}
      submitDisabled={submitDisabled}
    />
  )
}
