import React from "react";
import PropTypes from "prop-types";

import GreyTickIcon from "-!svg-react-loader?name=GreyTickIcon!icons/status-ic-grey-tick.svg";
import RedCrossIcon from "-!svg-react-loader?name=RedCrossIcon!icons/status-ic-red.svg";
import GreenTickIcon from "-!svg-react-loader?name=GreenTickIcon!icons/status-ic-green.svg";
import AmberTickIcon from "-!svg-react-loader?name=AmberTickIcon!icons/status-ic-amber.svg";

export default function TrainingRecordsTabStatus({
  trainingStatus
}) {
  switch (trainingStatus) {
    case "noRequirements": return (
      <div className="tw-flex tw-items-center">
        <GreyTickIcon className="tw-flex-none [&_rect]:tw-fill-grey-300" width={32} height={32} />
        <span className="tw-ml-3 tw-mr-3">
          You don't have any required training
        </span>
      </div>
    )
    case "expiring": return (
      <div className="tw-flex tw-items-center">
        <AmberTickIcon className="tw-flex-none [&_rect]:tw-fill-amber-400" width={32} height={32} />
        <span className="tw-ml-3 tw-mr-3">
          You have the required training but it expires soon
        </span>
      </div>
    )
    case "invalid": return (
      <div className="tw-flex tw-items-center">
        <RedCrossIcon className="tw-flex-none [&_rect]:tw-fill-red-500" width={32} height={32} />
        <span className="tw-ml-3 tw-mr-3">
          You don't have the required training
        </span>
      </div>
    )
    case "valid": return (
      <div className="tw-flex tw-items-center">
        <GreenTickIcon className="tw-flex-none [&_rect]:tw-fill-green-400" width={32} height={32} />
        <span className="tw-ml-3 tw-mr-3">
          You have the required training
        </span>
      </div>
    )
  }
}

TrainingRecordsTabStatus.propTypes = {
  trainingStatus: PropTypes.string.isRequired
}
