import React from "react";

import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "react-query-settings/queryClient";

import AppWrapper from "components/apps/AppWrapper";
import UserBar from "components/application/UserBar";

export default function UserBarWrapper({ contextProps, wrappedProps }) {
  return (
    <AppWrapper {...contextProps}>
      <QueryClientProvider client={queryClient}>
        <UserBar {...wrappedProps} />
      </QueryClientProvider>
    </AppWrapper>
  )
}
