import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProjectPersonnelTable from 'components/projectPersonnel/ProjectPersonnelTable';
import ProjectPersonnelBlankNotice from 'components/projectPersonnel/ProjectPersonnelBlankNotice';
import ProjectPersonnelBar from 'components/projectPersonnel/ProjectPersonnelBar';
import { ProjectPersonnelContext } from 'components/contexts/ProjectPersonnelContext';
import ProjectPersonnelRemovedToast from 'components/projectPersonnel/ProjectPersonnelRemovedToast';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';

export default function ProjectPersonnelTab(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();
  const [projectPersonnel, setProjectPersonnel] = useState({ collection: [], includedPersonnel: [], includedCompanyRoles: [], loaded: false })
  const [requestedVersion, setRequestedVersion] = useState({resource: null, loaded: false});
  const [latestVersion, setLatestVersion] = useState({resource: null, loaded: false});
  const [availableFields, setAvailableFields] = useState({ loaded: false, collection: [] });
  const [availableFieldOptions, setAvailableFieldOptions] = useState({ loaded: false, collection: [] });
  const projectPersonnelReviews = projectPersonnel.loaded && latestVersion.loaded && {
    haveSignedSameVersion: projectPersonnel.includedReviews.length > 0 && projectPersonnel.includedReviews.every((review) => {
      return (review.attributes.reviewedVersionId === projectPersonnel.includedReviews[0].attributes.reviewedVersionId) && review.attributes.state === 'accepted'
    }),
    haveSignedAnyVersion: projectPersonnel.includedReviews.length > 0 && projectPersonnel.includedReviews.every((review) => {
      return review.attributes.state === 'accepted'
    }),
    get signedVersion() { return this.haveSignedSameVersion && projectPersonnel.includedReviewedVersions[0] },
    get haveSignedLatestVersion() { return this.haveSignedSameVersion && latestVersion.resource.id === (this.signedVersion && this.signedVersion.id) }
  }

  const personnelWithEmailExists = projectPersonnel.loaded && projectPersonnel.includedPersonnel.find(personnel => personnel.attributes.email)

  const fetchProjectPersonnel = () => {
    axios
      .get(`/projects/${props.projectId}/project_personnel`)
      .then((response) => {
        setProjectPersonnel({
          collection: response.data.data,
          includedPersonnel: response.data.included.filter(inclusion => inclusion.type === 'personnel'),
          includedCompanyRoles: response.data.included.filter(inclusion => inclusion.type === 'companyRole'),
          includedReviews: response.data.included.filter(inclusion => inclusion.type === 'review'),
          includedReviewedVersions: response.data.included.filter(inclusion => inclusion.type === 'reviewedVersion'),
          loaded: true
        })
      })
      .catch(breadBoard.addInedibleToast)
  };
  const fetchFields = () => {
    axios
      .get('/custom_fields/available_field_attributes')
      .then((response) => {
        setAvailableFieldOptions({loaded: true, collection: response.data.included.filter(object => object.type === 'fieldOption')})
        setAvailableFields({loaded: true, collection: response.data.data})
      })
      .catch(breadBoard.addInedibleToast)
  }

  const replaceProjectPersonnel = (updatedProjectPersonnel) => {
    const projectPersonnelCopy = [...projectPersonnel.collection]
    const indexOfProjectPersonnel = projectPersonnelCopy.findIndex(pp => pp.id === updatedProjectPersonnel.id);
    projectPersonnelCopy[indexOfProjectPersonnel] = updatedProjectPersonnel
    const modifiedProjectPersonnelData = {...projectPersonnel, collection: [...projectPersonnelCopy]}
    setProjectPersonnel(modifiedProjectPersonnelData);
  }

  const destroyProjectPersonnel = (projectPersonnelId, displayName, undo) => {
    const _displayName = displayName;

    axios
      .delete(`/project_personnel/${projectPersonnelId}`)
      .then((_response) => {
        fetchProjectPersonnel();
        if (!undo) {
          breadBoard.addToast(
            <ProjectPersonnelRemovedToast
              displayName={_displayName}
            />
          )
        }
      })
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: <Fragment><span className='tw-font-medium'>{_displayName}</span> was not removed from the RAMS.<br/>Please try again.</Fragment>
        })
      })
  };

  const fetchRequestedVersion = () => {
    axios
      .get(`/projects/${props.projectId}/requested_version`)
      .then(response => setRequestedVersion({resource: response.data.data, loaded: true}))
      .catch(breadBoard.addInedibleToast)
  }

  const requestSignatures = () => {
    axios
      .post(`/projects/${props.projectId}/review_requests`)
      .then(_response => fetchResources())
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: <Fragment><span className='tw-font-medium'>{`Version ${latestVersion.resource.attributes.displayNumber}`}</span> was not sent.<br/>Please try again.</Fragment>
        })
      })
  };

  const fetchLatestVersion = () => {
    axios
      .get(`/projects/${props.projectId}/latest_version`)
      .then(response => setLatestVersion({resource: response.data.data, loaded: true}))
      .catch(breadBoard.addInedibleToast)
  }

  const fetchResources = () => {
    fetchProjectPersonnel()
    fetchFields()
    if (currentActor.isAllowedFeature('digital_signatures')) { fetchRequestedVersion() }
    fetchLatestVersion()
  }

  useEffect(() => {
    fetchResources()
  }, [])

  return (
    <ProjectPersonnelContext.Provider value={
      {
        projectStartDate: props.projectStartDate,
        projectEndDate: props.projectEndDate,
        userHasPersonnelAccess: props.userHasPersonnelAccess
      }
    }>
      <Fragment>
        {
          projectPersonnel.loaded && latestVersion.loaded && (
            <Fragment>
              <ProjectPersonnelBar
                requestedVersion={requestedVersion.resource}
                latestVersion={latestVersion.resource}
                requestSignatures={requestSignatures}
                projectPersonnelCount={projectPersonnel.collection.length}
                projectId={props.projectId}
                personnelWithEmailExists={personnelWithEmailExists}
                projectPersonnelReviews={projectPersonnelReviews}
                selectPersonnelOptions={
                  {
                    availableFields,
                    availableFieldOptions,
                    fetchProjectPersonnel,
                    destroyProjectPersonnel,
                    personnelIds: projectPersonnel.includedPersonnel.map(personnel => personnel.id),
                    projectId: props.projectId,
                    userHasRoleCreatableAccess: props.userHasRoleCreatableAccess,
                    userHasProfileAccessEditableAccess: props.userHasProfileAccessEditableAccess
                  }
                }
              />
              {
                projectPersonnel.collection.length > 0 ? (
                  <ProjectPersonnelTable
                    projectPersonnel={projectPersonnel.collection}
                    personnel={projectPersonnel.includedPersonnel}
                    companyRoles={projectPersonnel.includedCompanyRoles}
                    reviews={projectPersonnel.includedReviews}
                    reviewedVersions={projectPersonnel.includedReviewedVersions}
                    fetchProjectPersonnel={fetchProjectPersonnel}
                    replaceProjectPersonnel={replaceProjectPersonnel}
                    latestVersion={latestVersion.resource}
                    destroyProjectPersonnel={destroyProjectPersonnel}
                    availableFields={availableFields}
                    availableFieldOptions={availableFieldOptions}
                    projectId={props.projectId}
                  />
                ) : (
                  <ProjectPersonnelBlankNotice/>
                )
              }
            </Fragment>
          )
        }
      </Fragment>
    </ProjectPersonnelContext.Provider>
  )
}

ProjectPersonnelTab.propTypes = PropTypes.exact({
  projectId: PropTypes.string.isRequired,
  projectStartDate: PropTypes.string,
  projectEndDate: PropTypes.string,
  userHasPersonnelAccess: PropTypes.bool.isRequired,
  ramsPluralName: PropTypes.string,
  ramsSingularName: PropTypes.string
})
