import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';

import { personDisplayName } from 'components/helpers/users.js';
import { resourceShape } from 'components/helpers/serialisableResources';

import TextButton from 'components/application/buttons/TextButton';

export default function PersonnelSupportModal(props) {
  const {
    isOpen,
    onClose,
    lineManager
  } = props;

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' onClick={onClose}>
        Close
      </TextButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
            Support
          </h2>
        </div>
        <div className='modalbox-body'>
          {lineManager && lineManager.attributes.email && (
            <>
              <p className='tw-font-semibold tw-mb-0'>Spotted something incorrect?</p>
              <p>
                We recommend reaching out to {personDisplayName(lineManager.attributes)} {(<span className='tw-whitespace-nowrap'><span>(</span><a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href={`mailto:${lineManager.attributes.email}`}>{lineManager.attributes.email}</a><span>)</span></span>)} who will be able to help.
              </p>
            </>
          )}
          <p className='tw-font-semibold tw-mb-0'>Having technical issues?</p>
          <p className='tw-mb-0'>For help with your training profile, please speak to your training manager or <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' target='blank' href='https://intercom.help/hands-hq/en/articles/10213935-new-viewing-your-training-profile'>read our guide</a>.</p>
        </div>
      </>
    </ModalBox>
  )
}

PersonnelSupportModal.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lineManager: resourceShape('lineManager')
}
