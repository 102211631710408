
import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { getTrainingStatus, getTrainingExpiryDateLabel } from 'components/helpers/resources/training';

import TableCell from 'components/application/TableCell';
import TrainingName from 'components/training/TrainingName';
import TrainingNotesIcon from 'components/training/TrainingNotesIcon';

export default function TrainingRow(props) {
  const {
    isHistorical,
    isArchived,
    hasTrainingEditableAccess,
    training,
    attachments,
    course,
    isRequired,
    setModalBoxData,
    renderStatus,
    renderBookings,
    renderActions
  } = props;

  const currentActor = useCurrentActor();

  const modalData = { training: training, course: course, attachments: attachments };

  const handleNotesToggleClick = () => {
    setModalBoxData({ ...modalData, context: 'notes', loaded: true })
  };

  const handleAttachmentsToggleClick = () => {
    setModalBoxData({ ...modalData, context: 'attachments', loaded: true })
  };

  const currentTrainingStatus = getTrainingStatus({ training, course });

  return (
    <tr className='fh-49 tw-group/row hover:tw-bg-grey-025'>
      <TableCell style={isHistorical ? { borderTopColor: 'transparent' } : {}} justifyContent='center'>
        {renderStatus && renderStatus({ currentTrainingStatus })}
      </TableCell>
      <TableCell>
        <TrainingName
          isHistorical={isHistorical}
          showEvidenceRequirement={hasTrainingEditableAccess && !isArchived}
          training={training}
          attachments={attachments}
          course={course}
          isRequired={isRequired}
          onAttachmentsToggleClick={handleAttachmentsToggleClick}
        />
        {training && training.attributes.notes && (
          <span className='tw-ml-2'><TrainingNotesIcon onNotesToggleClick={handleNotesToggleClick} /></span>
        )}
      </TableCell>
      <TableCell>
        <span className='truncated-text-container'>{getTrainingExpiryDateLabel({ training, course })}</span>
      </TableCell>
      <TableCell>
        {renderBookings && <div className='flex'>{renderBookings({ currentTrainingStatus })}</div>}
      </TableCell>
      {currentActor.user.attributes.accessType !== 'personnel' && (
        <TableCell>
          {renderActions && renderActions({ training })}
        </TableCell>
      )}
    </tr>
  )
}

TrainingRow.propTypes = {
  isHistorical: PropTypes.bool,
  isArchived: PropTypes.bool,
  hasTrainingEditableAccess: PropTypes.bool,
  isRequired: PropTypes.bool,
  isWithinRoleTable: PropTypes.bool,
  course: PropTypes.object.isRequired,
  training: PropTypes.object,
  attachments: PropTypes.array,
  course: PropTypes.object,
  setModalBoxData: PropTypes.func,
  renderStatus: PropTypes.func,
  renderBookings: PropTypes.func,
  renderActions: PropTypes.func
}

TrainingRow.defaultProps = {
  isHistorical: false,
  isArchived: false,
  isRequired: true,
  hasTrainingEditableAccess: true,
  isWithinRoleTable: true,
  attachments: []
}
